import axios from "axios";
import moment from "moment";

// Set config defaults when creating the instance || Base URL
export const APISV = axios.create({
  baseURL: process.env.REACT_APP_APISV,
});

export const APIO2O = axios.create({
  baseURL: process.env.REACT_APP_APIO2O,
});

export const APIDOSS = axios.create({
  baseURL: process.env.REACT_APP_APIDOSS,
});

export const APIUTILS = axios.create({
  baseURL: process.env.REACT_APP_APIUTILS,
});

export const APIALTO = axios.create({
  baseURL: process.env.REACT_APP_APIALTO,
});

// Alter defaults after instance has been created || Integrate default header for auth
// export const setAuthToken = (token, officerId) => {
//   if (token) {
//     APISV.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//     APISV.defaults.headers.common["X-Officer-Id"] = officerId;
//   } else {
//     delete APISV.defaults.headers.common["Authorization"];
//     delete APISV.defaults.headers.common["X-Officer-Id"];
//   }
// };

// export const setAuthTokenO2O = (token) => {
//   if (token) {
//     APIO2O.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   } else {
//     delete APIO2O.defaults.headers.common["Authorization"];
//   }
// };

APISV.interceptors.request.use(
  async (req) => {
    if (req.headers === undefined) {
      req.headers = {};
    }

    const token = localStorage.getItem("token");
    const expired_at = localStorage.getItem("expired_at");
    const user_info = JSON.parse(localStorage.getItem("userInfo"));

    let accessToken = token;
    const { id } = user_info;

    if (moment(new Date()).isSameOrAfter(expired_at)) {
      localStorage.clear();
      window.location.replace("/login");
    } else {
      req.headers.Authorization = `Bearer ${accessToken}`;
      req.headers.common["X-Officer-Id"] = id;
    }

    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);

APIO2O.interceptors.request.use(
  async (req) => {
    if (req.headers === undefined) {
      req.headers = {};
    }

    const access_token = localStorage.getItem("access_token");
    const expired_at = localStorage.getItem("expired_at");

    console.log("access_token", access_token);
    console.log("expired_at", expired_at);

    let accessToken = access_token;

    if (moment(new Date()).isSameOrAfter(expired_at)) {
      localStorage.clear();
      window.location.replace("/login");
    } else {
      req.headers.Authorization = `Bearer ${accessToken}`;
    }

    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);

APISV.interceptors.response.use(
  async (response) => {
    if (response.data.error === 401) {
      localStorage.clear();
      return window.location.replace("/login");
    }
    return response;
  },
  (error) => {
    return error;
  }
);

APIO2O.interceptors.response.use(
  async (response) => {
    if (response.data.error === 401) {
      localStorage.clear();
      return window.location.replace("/login");
    }
    return response;
  },
  (error) => {
    return error;
  }
);
