import React, { useContext, useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { AppContext } from "../configs/AppContext";
import NumberFormat from "react-number-format";

import { APISV } from "../configs/API";
import SNInput from "./SNInput";
import TotalSummary from "./TotalSummary";
import TitleSidebar from "./TitleSidebar";

const SNSidebar = () => {
  const [state, dispatch] = useContext(AppContext);
  const [filterSN, setFilterSN] = useState([]);

  const { activeCart, activeTax } = state;

  const handleClose = () => {
    dispatch({
      type: "SET_SN_SHOW",
      payload: false,
    });

    dispatch({
      type: "SET_CART_SHOW",
      payload: true,
    });
  };

  const handleNext = () => {
    dispatch({
      type: "SET_SN_SHOW",
      payload: false,
    });
    dispatch({
      type: "SET_SHIPPING_SHOW",
      payload: true,
    });
  };

  const getActiveItems = async () => {
    try {
      const res = await APISV.get(`s-cart/${activeCart.id}/item`);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  useEffect(() => {
    state.snShow && getActiveItems();
  }, [state.snShow]);

  return activeCart ? (
    <Offcanvas show={state.snShow} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <TitleSidebar activeCart={activeCart} />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="fw-bold">SERIAL NUMBER PRODUCT</div>
        <hr />
        <div className="cart-sidebar-item">
          {activeCart.items.map((item, index) => (
            <div key={index}>
              {[...Array(item.quantity).keys()].map((product, index2) => (
                <div key={index2}>
                  <SNInput
                    item={item}
                    index2={index2}
                    index={`#${item.id}`}
                    filterSN={filterSN}
                    setFilterSN={(e) => setFilterSN(e)}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <TotalSummary activeCart={activeCart} tax={activeTax} />

        <hr />

        <div className="d-flex justify-content-between align-items-center">
          <div className="fw-bold">Total Belanja</div>

          <div className="fw-bold" style={{ color: "red" }}>
            <NumberFormat
              value={activeCart.total}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              prefix={"IDR "}
            />
          </div>
        </div>

        <button
          className="btn btn-primary btn-checkout fw-bold mt-2"
          onClick={handleNext}
        >
          Lanjutkan
        </button>
      </Offcanvas.Body>
    </Offcanvas>
  ) : null;
};

export default SNSidebar;
