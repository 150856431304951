import React, { useContext } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";

import { IoIosAddCircle } from "react-icons/io";
import { IoTrash } from "react-icons/io5";

import NoImage from '../assets/images/no-img.jpg'
import { AppContext } from "../configs/AppContext";
import { useNavigate } from "react-router-dom";
import { APISV } from "../configs/API";

const EventCard = (props) => {
    const [state, dispatch] = useContext(AppContext);
    let activeCartEvent = localStorage.getItem("active_cart_event");
    let activeCustomer = localStorage.getItem("active_customer");
    const navigate = useNavigate();

    if (activeCartEvent) {
        activeCartEvent = JSON.parse(localStorage.getItem("active_cart_event"));
    }

    if (activeCustomer) {
        activeCustomer = JSON.parse(localStorage.getItem("active_customer"));
    }

    const addDefaultSrc = (url) => {
        let webURL = url;
        if (url.substr(url.length - 5) !== ".webp") {
            if (url.substr(url.length - 5) === ".jpeg") {
                webURL = url.substr(0, url.length - 4) + "webp";
            } else {
                webURL = url.substr(0, url.length - 3) + "webp";
            }
        }
        const str = webURL;
        const filename = str.replace(/^.*[\\\/]/, "");
        const sub = str.replace(filename, "").replace("medium/", "");

        return sub + filename;
    };


    function isInCart(eventId) {
        if (activeCartEvent) return activeCartEvent.event_id == eventId;
        return false;
    }

    async function addToCart() {
        try {
            dispatch({
                type: "SET_LOADING",
                payload: true,
            });

            if (!activeCustomer) return navigate("/customers/search");

            const payload = {
                "event_id": props.event.id,
                "ticket_id": props.event.ticket.id,
                "customer_id": activeCustomer.id,
                "quantity": 1
            };

            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            if (!activeCartEvent) {
                const resCreate = await APISV.post(
                    `events/cart`,
                    payload,
                    config
                );

                if (resCreate.data.error == 0) {
                    if (resCreate.data.message) {
                        dispatch({
                            type: "SET_TOAST",
                            payload: {
                                isOpen: true,
                                message: resCreate.data.message,
                            },
                        });
                    }
                 
                    dispatch({
                        type: "SET_ACTIVE_CART_EVENT",
                        payload: resCreate.data.data,
                    });
                } else {
                    alert(
                        `Error ${resCreate.data.error} | ${resCreate.data.message} `
                    );
                    // sebelum digunakan untuk mengahandle card event yang nyangkut
                    // if (resCreate.data.message.startsWith('You have another active cart')) {
                    //     const resList = await APISV.get('events/cart', config)
                        
                    //     if (resList.data.error == 0){
                    //         const hasCustomerActiveChart = (resList?.data?.data || []).length > 0 ? resList.data.data.find(actEvt => actEvt.customer === activeCustomer.id ) : null

                    //         if (hasCustomerActiveChart?.id){
                    //             const resRemove = await APISV.delete(`events/cart/${hasCustomerActiveChart.id}`, config)

                    //             if (resRemove?.data?.error === 0) {
                    //                 addToCart()
                    //             } else {
                    //                 alert(
                    //                     `Error ${resRemove.data.error} | ${resRemove.data.message} `
                    //                 );
                    //             }
                    //         }
                    //     } else {
                    //         alert(
                    //             `Error ${resList.data.error} | ${resList.data.message} `
                    //         );
                    //     }
                    // } else {
                    //     alert(
                    //         `Error ${resCreate.data.error} | ${resCreate.data.message} `
                    //     );
                    // }
                }
            } else {
                alert('Chart already exist')
            }

        } catch (error) {
            if (error.response.data) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }
        } finally {
            dispatch({
                type: "SET_LOADING",
                payload: false,
            });

        }
    }

    async function removeFromCart() {
        try {
            dispatch({
                type: "SET_LOADING",
                payload: true,
            });

            const res = await APISV.delete(`events/cart/${activeCartEvent.id}`);
            if (res.data.error == 0) {
                dispatch({
                    type: 'DELETE_ACTIVE_CART_EVENT'
                })
            } else {
                alert(res.data.description);
            }

        } catch (error) {
            alert(error.response.data.message);
        } finally {
            dispatch({
                type: "SET_LOADING",
                payload: false,
            });
        }
    }

    return (
        <div
            key={props.event.id}
            style={{
                backgroundColor: 'white',
                boxShadow: '0 20px 30px 0 rgb(0 0 0 / 6%)',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer'
            }}
        >

            <img
                src={
                    props.event.image
                        ? addDefaultSrc(props.event.image)
                        : props.event.images !== null
                            ? addDefaultSrc(props.event.images[0])
                            : NoImage
                }
                alt={
                    props.event.title_label ? props.event.title_label : props.event.name
                }
                style={{
                    width: '100%',
                    borderTopRightRadius: '10px',
                    borderTopLeftRadius: '10px',
                    maxHeight: '200px',
                    objectFit: 'cover'
                }}
            />
            <div style={{ flex: 1, padding: '1rem' }}>
                <div className="product-id-txt mb-2">#{props.event.id}</div>
                <div className="product-name-txt">
                    {props.event.title_label
                        ? props.event.title_label
                        : props.event.name}
                </div>
                {props.event.price == 0 && (
                    <div className="event-card-price">Gratis!</div>
                )}

                <div className="price-event-section">
                    {props.event.price !== 0 && (
                        <NumberFormat
                            value={props.event.price}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix={"IDR "}
                            className="product-price-txt mt-2"
                        />
                    )}
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {isInCart(props.event.id) ? (
                        <IoTrash
                            size={30}
                            className=""
                            style={{
                                color: 'var(--primary)'
                            }}
                            onClick={removeFromCart}
                        />
                    ) : (
                        <IoIosAddCircle
                            size={35}
                            className=""
                            onClick={addToCart}
                            style={{
                                color: 'var(--primary)'
                            }}
                        />
                    )}

                </div>
            </div>
        </div>
    );
};

export default EventCard;