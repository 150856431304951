import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import Skeleton from "react-loading-skeleton";
import { AppContext } from "../configs/AppContext";

import Sidebar from "../components/Sidebar";
import TopNavbar from "../components/TopNavbar";
import { APISV } from "../configs/API";

const CustomerDP = () => {
  const [state, dispatch] = useContext(AppContext);
  const { activeCustomer } = state;
  const branch = JSON.parse(localStorage.getItem("branch"));
  const { id: branch_id, name: branch_name } = branch;

  const [dpList, setDpList] = useState(null);
  const [formData, setFormData] = useState({
    branch_id: branch_id,
    branch_name: branch_name,
    amount: "",
    description: "",
    customer_id: activeCustomer.id,
  });

  const { amount, description } = formData;

  const getDownPayment = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APISV.get(`/dp?customer_id=${activeCustomer.id}`);

      if (res.data.error == 0) {
        setDpList(res.data.data);
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      let payload = formData;
      payload.amount = convertNumber(payload.amount);

      const res = await APISV.post("/dp", payload);

      if (res.data.error == 0) {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
        setFormData({ ...formData, amount: 0, description: "" });

        getDownPayment();
      } else {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
        alert(res.data.message);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const convertNumber = (value) => {
    if (value === "") {
      return 0;
    } else {
      return parseInt(value.substring(4).replace(/\./g, ""));
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getDownPayment();
  }, []);

  return (
    activeCustomer && (
      <>
        <div className="main-container">
          <Sidebar />

          <div
            className="w-100"
            style={{
              paddingLeft: state.collapse ? "80px" : "270px",
              transition: "width, left, right, 0.3s",
            }}
          >
            <TopNavbar title="History" disableSearch={true} />

            <div className="d-flex justify-content-between px-4">
              <div>
                <h1 className="header-h1">Down Payment</h1>
                <h5 className="header-h5">
                  Nama Customer: {activeCustomer.fullname}
                </h5>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-4">
                  <div className="p-4">
                    <label className="color-grey">Nominal DP*</label>
                    <NumberFormat
                      name="amount"
                      value={amount}
                      onChange={handleChange}
                      prefix={"IDR "}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      placeholder="Masukan nominal DP"
                    />

                    <label className="color-grey mt-3">Deskripsi*</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Masukan deskripsi"
                      name="description"
                      value={description}
                      onChange={handleChange}
                    />

                    <button
                      type="submit"
                      className="btn btn-primary w-100 mt-4"
                      disabled={!amount || !description}
                    >
                      Buat DP Baru
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <div className="row">
              <div className="col">
                <div className="table-search-card p-4">
                  <table>
                    <tr>
                      <th className="text-center">ID</th>
                      <th>Detail Customer</th>
                      <th>Deskripsi</th>
                      <th>Jumlah DP</th>
                    </tr>
                    {!dpList && (
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    )}
                    {dpList &&
                      dpList.map((dp) => (
                        <tr key={dp.id}>
                          <td className="text-center">{dp.id}</td>
                          <td>
                            <div>email: {dp.customer?.email}</div>
                            <div>nama: {dp.customer?.fullname}</div>
                          </td>
                          <td>{dp.description}</td>
                          <td>
                            <NumberFormat
                              value={dp.amount}
                              displayType={"text"}
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix={"IDR "}
                            />
                          </td>
                        </tr>
                      ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default CustomerDP;
