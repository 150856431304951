import React, { useState, useContext } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { IoMdClose, IoMdArrowDropdown } from "react-icons/io";
import NumberFormat from "react-number-format";

import { AppContext } from "../configs/AppContext";
import { APIO2O } from "../configs/API";

const ModalCoupon = ({ show, handleClose, customerId, applyCoupon }) => {
  const [state, dispatch] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    amount: "",
    customer_id: customerId,
    vendor: "DOSS",
  });

  const { name, code, amount, customer_id, vendor } = formData;

  const createCoupon = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const convertAmount = parseInt(amount.substring(4).replace(/\./g, ""));
      const payload = {
        name,
        code,
        amount: convertAmount,
        customer_id,
        vendor,
      };
      const res = await APIO2O.post("platform/promo/coupon/create", payload);

      if (res.data.error == 0) {
        setLoading(false);

        handleClose();

        dispatch({
          type: "SET_TOAST",
          payload: {
            isOpen: true,
            message: "Kupon Berhasil Dibuat",
          },
        });

        applyCoupon(null, code);

        setFormData({
          name: "",
          code: "",
          amount: "",
          customer_id: customerId,
        });
      } else {
        setLoading(false);
        alert(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error.response.data.message);
    }
  };

  const closeModal = () => {
    setFormData({
      name: "",
      code: "",
      amount: "",
      customer_id: customerId,
      vendor: "DOSS",
    });
    handleClose();
  };

  return (
    <Modal centered show={show} onHide={closeModal} backdrop="static">
      <Modal.Body>
        <div className="d-flex justify-content-between mb-4">
          <div className="modal-coupon-header">Buat Kupon</div>
          <IoMdClose
            className="cursor-pointer"
            size={20}
            onClick={closeModal}
          />
        </div>

        <form onSubmit={createCoupon}>
          <label>Nama Kupon</label>
          <input
            className="form-control"
            type="text"
            placeholder="Masukan Nama Kupon"
            name="name"
            value={name}
            onChange={(e) =>
              setFormData({ ...formData, [e.target.name]: e.target.value })
            }
          />

          <label className="mt-3">Kode Kupon</label>
          <input
            className="form-control"
            type="text"
            placeholder="Masukan Kode Kupon"
            name="code"
            value={code}
            onChange={(e) =>
              setFormData({ ...formData, [e.target.name]: e.target.value })
            }
          />

          <label className="mt-3">Jumlah Diskon</label>

          <NumberFormat
            thousandSeparator="."
            decimalSeparator=","
            prefix={"IDR "}
            className="form-control"
            placeholder="Masukan Jumlah Diskon"
            name="amount"
            value={amount}
            onChange={(e) =>
              setFormData({ ...formData, [e.target.name]: e.target.value })
            }
          />

          <label className="mt-3">Vendor</label>

          <div className="position-relative">
            <select
              className="form-control"
              value={vendor}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  vendor: e.target.value,
                })
              }
            >
              <option value="DOSS">DOSS</option>
              <option value="Principal">Principal</option>
            </select>

            <IoMdArrowDropdown
              className="arrow-select"
              size={25}
              color="gray"
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary btn-checkout fw-bold mt-4"
            disabled={loading}
            // onClick={handleNext}
          >
            {loading ? (
              <Spinner
                animation="border"
                style={{ color: "#ffff", width: "25px", height: "25px" }}
              />
            ) : (
              "Simpan"
            )}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCoupon;
