import React, { useContext, useState } from "react";
import { AppContext } from "../configs/AppContext";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Hits, Configure } from "react-instantsearch-hooks-web";

import Sidebar from "../components/Sidebar";
import TopNavbar from "../components/TopNavbar";
import CartPopup from "../components/CartPopup";
import CartSidebar from "../components/CartSidebar";
import TaxSidebar from "../components/TaxSidebar";
import SNSidebar from "../components/SNSidebar";
import SearchBox from "../components/SearchBox";
import ProductCard from "../components/ProductCard";
import ShippingSidebar from "../components/ShippingSidebar";

const client = algoliasearch("C0ABMABFNV", "7296021cf6d52f3dcaa24d50a4dc2269");

const Products = (props) => {
  const [state, dispatch] = useContext(AppContext);
  const [products, setProducts] = useState(null);
  const [type, setType] = useState("keyword");

  const hitComponent = ({ hit }) => {
    return <ProductCard product={hit} />;
  };

  return (
    <InstantSearch searchClient={client} indexName="DOSS_PRODUCT_MAIN">
      <Configure filters="product_status:1" />

      <div className="main-container pb-5">
        <Sidebar />
        <CartPopup />
        <CartSidebar />
        <SNSidebar />
        <ShippingSidebar />
        <TaxSidebar />

        <div
          className="w-100"
          style={{
            paddingLeft: state.collapse ? "80px" : "270px",
            transition: "width, left, right, 0.3s",
          }}
        >
          <TopNavbar title="Product" />

          <div className="px-4">
            <SearchBox
              {...props}
              type={type}
              setType={setType}
              setProducts={setProducts}
              dispatch={dispatch}
            />

            {type === "keyword" && <Hits hitComponent={hitComponent} />}

            {type === "productId" && (
              <>
                {products &&
                  products.map((product, index) => (
                    <ProductCard key={index} product={product} />
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
    </InstantSearch>
  );
};

export default Products;
