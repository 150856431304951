import React, { useContext } from "react";
import { Modal, Spinner } from "react-bootstrap";

import { AppContext } from "../configs/AppContext";

const ModalLoading = () => {
  const [state, dispatch] = useContext(AppContext);

  const handleClose = () => {
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
  };

  return (
    <Modal
      backdrop="static"
      centered
      show={state.loading}
      onHide={handleClose}
      className="loading-modal"
    >
      <Spinner animation="border" style={{ color: "#ee6026" }} />
    </Modal>
  );
};

export default ModalLoading;
