import React, { useContext, useEffect } from "react";
import { AppContext } from "../configs/AppContext";
import { useNavigate } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import TopNavbar from "../components/TopNavbar";

const Home = () => {
  const [state, dispatch] = useContext(AppContext);
  const branch = localStorage.getItem("branch");
  const warehouse = localStorage.getItem("warehouse");
  const navigate = useNavigate();

  useEffect(() => {
    if (!branch || !warehouse) {
      navigate("/warehouse");
    }
  }, [branch, warehouse]);

  return (
    <div className="main-container">
      <Sidebar />

      <div
        className="w-100"
        style={{
          paddingLeft: state.collapse ? "80px" : "270px",
          transition: "width, left, right, 0.3s",
        }}
      >
        <TopNavbar title="" disableSearch={true} />
      </div>
    </div>
  );
};

export default Home;
