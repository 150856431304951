import React, { useContext, useState } from "react";
import NumberFormat from "react-number-format";
import Skeleton from "react-loading-skeleton";
import { Dropdown } from "react-bootstrap";
import { AppContext } from "../configs/AppContext";

import Sidebar from "../components/Sidebar";
import TopNavbar from "../components/TopNavbar";
import ModalOrderReady from "../components/ModalOrderReady";
import ModalPickup from "../components/ModalPickup";
import ModalOrderReadyTable from "../components/ModalOrderReadyTable";
import ModalPickupTable from "../components/ModalPickupTable";

const PickupAtStore = () => {
  const [state, dispatch] = useContext(AppContext);
  const [modalOrder, setModalOrder] = useState(false);
  const [modalPickup, setModalPickup] = useState(false);
  const [modalOrderTable, setModalOrderTabel] = useState(false);
  const [modalPickupTable, setModalPickupTable] = useState(false);

  return (
    <>
      <ModalOrderReady
        show={modalOrder}
        handleClose={() => setModalOrder(false)}
      />
      <ModalPickup
        show={modalPickup}
        handleClose={() => setModalPickup(false)}
      />
      <ModalOrderReadyTable
        show={modalOrderTable}
        handleClose={() => setModalOrderTabel(false)}
      />
      <ModalPickupTable
        show={modalPickupTable}
        handleClose={() => setModalPickupTable(false)}
      />

      <div className="main-container">
        <Sidebar />

        <div
          className="w-100"
          style={{
            paddingLeft: state.collapse ? "80px" : "270px",
            transition: "width, left, right, 0.3s",
          }}
        >
          <TopNavbar title="Pickup At Store" disableSearch={true} />

          <div className="d-flex justify-content-between px-4">
            <h1 className="header-h1"></h1>

            <div>
              <button
                className="btn btn-secondary customer-add-btn me-2"
                style={{ height: "48px" }}
                onClick={() => setModalOrder(true)}
              >
                Order Siap Diambil
              </button>
              <button
                className="btn btn-primary customer-add-btn"
                onClick={() => setModalPickup(true)}
              >
                Pick Up
              </button>
            </div>
          </div>

          <div className="p-4">
            *Dummy data only
            <div className="table-search-card">
              <table>
                <tr>
                  <th>Order ID</th>
                  <th>Trans ID</th>
                  <th>Customer Name</th>
                  <th>Item List</th>
                  <th>Total Transaction</th>
                  <th>Action</th>
                </tr>

                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
                <tr>
                  <td>XC000000043037</td>
                  <td className="fw-bold">33746</td>
                  <td>Satria Yudhia P</td>
                  <td>
                    <ul>
                      <li>
                        Lexar 32GB Professional 800x SDHC UHS-I Memory Card
                      </li>
                      <li>
                        LINDY 36891 1M Kabel USB Type C to Micro USB DAC Cable
                        480Mbps
                      </li>
                    </ul>
                  </td>
                  <td>
                    {" "}
                    <NumberFormat
                      value={23999000}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={"IDR "}
                    />
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="btn btn-secondary d-flex align-items-center"
                      ></Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setModalOrderTabel(true)}>
                          Order Siap Diambil
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setModalPickupTable(true)}
                        >
                          Order Sudah di Pickup
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PickupAtStore;
