import React, { useState } from "react";
import { useSearchBox } from "react-instantsearch-hooks-web";

import { APIDOSS } from "../configs/API";

import { BsSearch } from "react-icons/bs";

const SearchBox = (props) => {
  const { type, setType, setProducts, dispatch } = props;
  const { query, refine, clear, isSearchStalled } = useSearchBox(props);
  const [keyword, setKeyword] = useState("");

  const handleSearch = async (e) => {
    e.preventDefault();

    if (type === "keyword") {
      refine(keyword);
    } else {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const res = await APIDOSS.get(`product/${keyword}`);
      setProducts([res.data.data]);

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };

  const handleChange = (e) => {
    setKeyword(e.target.value);
    refine(e.target.value);
  };

  return (
    <form onSubmit={handleSearch}>
      <div className="top-navbar-search-by mb-2">
        <span className="me-2">Cari Berdasarkan: </span>

        <input
          type="radio"
          id="keyword"
          name="keyword"
          value="keyword"
          checked={type === "keyword"}
          onChange={(e) => setType(e.target.value)}
          className="me-1"
        />
        <label htmlFor="keyword" className="cursor-pointer me-4">
          Keyword
        </label>

        <input
          type="radio"
          id="productId"
          name="productId"
          value="productId"
          checked={type === "productId"}
          onChange={(e) => setType(e.target.value)}
          className="me-1"
        />
        <label htmlFor="productId" className="cursor-pointer">
          Product ID
        </label>
      </div>
      <div className="d-flex">
        <div className="top-navbar-input mb-3 me-2">
          <BsSearch className="top-navbar-icon" />
          <input
            type="text"
            placeholder="Cari product.."
            className="form-control"
            style={{ height: "48px", width: "500px" }}
            value={keyword}
            onChange={handleChange}
          />

          <button
            type="submit"
            className="btn btn-primary top-navbar-btn"
            style={{ width: "100px" }}
          >
            Cari
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchBox;
