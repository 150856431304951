import React, { useState, useContext, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { IoMdClose, IoMdArrowDropdown } from "react-icons/io";

import { AppContext } from "../configs/AppContext";
import { APIUTILS, APIO2O } from "../configs/API";

const ModalPickup = ({ show, handleClose }) => {
  const [state, dispatch] = useContext(AppContext);
  const [stores, setStores] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    trans_id: "",
    location: "",
    pickup_code: "",
  });

  const { trans_id, location, pickup_code } = formData;

  const getStores = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APIUTILS.get("location/list");

      if (res.data.error == 0) {
        setStores(res.data.data);
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = { action: "complete" };
      const res = await APIO2O.post(`/platform/pick/${trans_id}`, payload);

      if (res.data.error == 0) {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });

        dispatch({
          type: "SET_TOAST",
          payload: {
            isOpen: true,
            message: "Berhasil simpan status",
          },
        });

        setFormData({
          trans_id: "",
          location: "",
          pickup_code: "",
        });

        handleClose();
      } else {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
        alert(res.data.message);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  useEffect(() => {
    // show && getStores();
  }, []);

  return (
    <Modal centered show={show} onHide={handleClose} backdrop="static">
      <Modal.Body>
        <div className="d-flex justify-content-between mb-4">
          <div className="modal-coupon-header">Pengambilan Order</div>

          <IoMdClose
            className="cursor-pointer"
            size={20}
            onClick={handleClose}
          />
        </div>

        <form onSubmit={handleSubmit}>
          <label>Trans ID</label>
          <input
            className="form-control"
            type="text"
            placeholder="Contoh: 21984"
            name="trans_id"
            value={trans_id}
            onChange={(e) =>
              setFormData({ ...formData, [e.target.name]: e.target.value })
            }
          />

          {/* <label className="mt-3">Cabang</label>
          <div className="position-relative">
            <select
              className="form-control"
              name="location"
              value={location}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
            >
              {stores &&
                stores.map((store) => (
                  <option key={store.id} value={store.id}>
                    {store.name}
                  </option>
                ))}
            </select>

            <IoMdArrowDropdown
              className="arrow-select"
              size={25}
              color="gray"
            />
          </div> */}

          {/* <label className="mt-3">Kode Pengambilan</label>
          <input
            className="form-control"
            type="text"
            placeholder="XXXXXX"
            name="pickup_code"
            value={pickup_code}
            onChange={(e) =>
              setFormData({ ...formData, [e.target.name]: e.target.value })
            }
          /> */}

          <button
            className="btn btn-primary btn-checkout fw-bold mt-4"
            disabled={loading || !trans_id}
            // onClick={handleNext}
          >
            {loading ? (
              <Spinner
                animation="border"
                style={{ color: "#ffff", width: "25px", height: "25px" }}
              />
            ) : (
              "Sudah Diambil"
            )}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPickup;
