import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import TopNavbar from "../components/TopNavbar";
import { AppContext } from "../configs/AppContext";
import { APIDOSS, APISV, APIUTILS } from "../configs/API";
import EventCard from "../components/EventCard";
import { Offcanvas, Card, Container, Row, Col, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { BsFillCartCheckFill, BsGrid } from "react-icons/bs";
import { HiOutlineTrash } from "react-icons/hi";
import NumberFormat from "react-number-format";
import { IoIosAddCircle, IoIosRemoveCircle, IoMdTrash } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import TotalSummary from "../components/TotalSummary";
import useElementInView from "../hooks/useElementInView";
import { FaSortAmountDown } from "react-icons/fa";
import ModalGenereEvent from "../components/ModalGenreEvent";
import ModalSortEvent from "../components/ModalSortEvent";
import { MdClose } from "react-icons/md";

const LoadMore = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default function Events() {
  const [state, dispatch] = useContext(AppContext);
  const { activeCartEvent, activeCustomer, collapse } = state;
  const [events, setEvents] = useState([]);
  const [pageEvent, setPageEvent] = useState(1);
  const [sortBy, setSortBy] = useState("&sort=desc&sort_field=date");
  const [categoryId, setCategoryId] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [qty, setQty] = useState(activeCartEvent?.quantity || 1);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const dbList = JSON.parse(localStorage.getItem("db_list"));
  const branch = JSON.parse(localStorage.getItem("branch"));
  const [channels, setChannels] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const warehouse = JSON.parse(localStorage.getItem("warehouse"));
  const [formData, setFormData] = useState({ payment_channel: 2 });
  const [targetRef, isLoadMore] = useElementInView({ threshold: 0.1 });
  const [reachEnd, seatReachEnd] = useState(false);
  const [modalSort, setModalSort] = useState(false);
  const [modalCategory, setModalCategory] = useState(false);
  const [modalCheckoutEvent, setModalCheckoutEvent] = useState(false);

  const navigate = useNavigate();
  const { payment_channel } = formData;

  useEffect(() => {
    getChannel();
  }, []);

  useEffect(() => {
    if (!reachEnd)
      getEvents(sortBy, categoryId, true, true);
  }, [isLoadMore]);

  useEffect(() => {
    if (categoryId != null)
      getEvents(sortBy, categoryId, false);
  }, [categoryId]);

  useEffect(() => {
    getEvents(sortBy, categoryId, false);
  }, [sortBy]);

  useEffect(() => {
    if (activeCartEvent) {
      updateQuantity();
    }
  }, [qty]);

  const getChannel = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APIUTILS.get("pay/list_channel");

      if (res.data.error == 0) {
        var arrayData = res.data.data;
        arrayData.pop();
        setChannels(arrayData);
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  async function removeFromCart() {
    if (!window.confirm("Apakah Anda Yakin ?")) return;

    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APISV.delete(`events/cart/${activeCartEvent.id}`);
      if (res.data.error == 0) {
        dispatch({
          type: "DELETE_ACTIVE_CART_EVENT",
        });
      } else {
        alert(res.data.description);
      }
    } catch (error) {
      alert(error.response.data.message);
    } finally {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  }

  async function getEvents(sortSelected = null, categorySelectedID = null, isNextPage = false, isLoadMore = false) {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const url = `events?per_page=14&page=${isNextPage ? pageEvent : 1}${sortSelected ? sortSelected : sortBy}${categorySelectedID ? "&genre_id=" + categorySelectedID : ""
        }`;
      const res = await APIDOSS.get(url);


      if (res.data.meta.last_page == res.data.meta.current_page) {
        seatReachEnd(true);
      }

      if (res.data.meta.current_page > 1) {
        if (isNextPage) {
          let evt = events;

          evt.push(...res.data.data);
          console.log("sini ga ?");
          setEvents((prev) => [...prev, ...res.data.data]);
        }
      } else {
        console.log("sini salash");
        setEvents(res.data.data);
      }
      setPageEvent(parseInt(res.data.meta.current_page) + 1);


    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });

    }
  }

  const updateQuantity = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APISV.patch(`events/cart/${activeCartEvent.id}`, {
        quantity: qty,
      });

      if (res.data.error == 0) {
        var data = { ...res.data.data };
        data = { ...data, subtotal: data.price * qty, total: data.price * qty };
        dispatch({
          type: "SET_ACTIVE_CART_EVENT",
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };

  const resetData = () => {
    setFormData({ payment_channel: 2 });
  };

  const handleCheckout = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      let payload = {
        payment_channel: parseInt(payment_channel),
      };

      const res = await APISV.post(`events/cart/${activeCartEvent.id}`, payload);

      if (res.data.error == 0) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            isOpen: true,
            message: "Berhasil checkout event",
          },
        });
        setShowSidebar(false);
        // sebelumnya untuk handle active cart yang nyangkut
        // await APISV.delete(`events/cart/${activeCartEvent.id}`);
        dispatch({
          type: "DELETE_ACTIVE_CART_EVENT",
        });

        if (payment_channel == 2) {
          navigate({ pathname: "/customers/history" }, { state: { inv_url: res.data.data.inv_url } });
        } else {
          dispatch({
            type: "DELETE_ACTIVE_CUSTOMER",
          });
          navigate("/");
        }

        resetData();
      } else if (res.data.error == 403) {
        if (res.data.errors.some((error) => error["id"] && error["message"])) {
          alert(`${res.data.errors[0].id} \n${res.data.errors[0].message}`);
        } else {
          alert(
            res.data.errors.map((error) => {
              return `${error}\n`;
            })
          );
        }
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const chooseCategory = (e) => {
    setCategoryId(e.id);
    setPageEvent(1);
    seatReachEnd(false)
  };

  const setSort = (e) => {
    setSortBy(e);
    setPageEvent(1);
    seatReachEnd(false)
  };

  const ModalConfirmCheckout = () => {
    return (
      <Modal show={modalCheckoutEvent} onHide={() => setModalCheckoutEvent(false)} centered>
        <Container className="p-4">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "5%",
              marginBottom: "5%",
            }}
          >
            <h5 className="change-address-title">Konfirmasi Pembayaran</h5>
            <MdClose className="close" size={25} onClick={() => setModalCheckoutEvent(false)} />
          </div>
          <p className="mb-4">
            {`Dengan melanjutkan, Anda menyetujui untuk melakukan pembayaran sebesar`}
            <span>
              <NumberFormat
                value={activeCartEvent?.total || "0"}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                prefix={" IDR "}
                className="total-cart-price fw-bold"
              />
            </span>
            {` melalui Virtual Account yang tersedia. Pastikan untuk menyelesaikan transaksi dalam batas waktu yang ditentukan.`}
          </p>
          <div className="d-flex justify-content-end align-items-center gap-3">
            <button
              className="btn btn-primary customer-add-btn fw-bold"
              disabled={isDisabled}
              onClick={() => {
                setModalCheckoutEvent(false)
                handleCheckout()
              }}
            >
              Checkout
            </button>
            <button style={{ height: "48px" }} className="btn btn-secondary customer-add-btn fw-bold" onClick={() => {
              setModalCheckoutEvent(false)
            }}>Batal</button>
          </div>
        </Container>
      </Modal>
    )
  }


  return (
    <div className="main-container pb-5">
      <Sidebar />

      <div
        className="w-100"
        style={{
          paddingLeft: collapse ? "80px" : "270px",
          transition: "width, left, right, 0.3s",
        }}
      >
        <TopNavbar title="Events" />

        <ModalSortEvent
          show={modalSort}
          handleClose={() => setModalSort(false)}
          sortBy={sortBy}
          chooseSort={(e) => setSort(e)}
        />

        <ModalGenereEvent
          show={modalCategory}
          handleClose={() => setModalCategory(false)}
          choose={(e) => chooseCategory(e)}
          activeId={categoryId}
          reset={() => setCategoryId(null)}
        />

        <ModalConfirmCheckout
          show={true}
          handleClose={() => setModalCategory(false)}
          choose={(e) => chooseCategory(e)}
          activeId={categoryId}
          reset={() => setCategoryId(null)}
        />

        <div
          className=""
          style={{
            paddingInline: "3rem",
            paddingTop: "2rem",
          }}
        >
          <Row className="event-container">
            <Card.Header className="event-sorting-container p-2">
              <div className="event-option-container">
                <div className="event-icon-container" onClick={() => setModalSort(true)}>
                  <FaSortAmountDown size={25} />
                  <div className="event-sub-text">Urutkan</div>
                </div>

                <div className="event-icon-container" onClick={() => setModalCategory(true)}>
                  <BsGrid size={25} />
                  <div className="event-sub-text">Genre</div>
                </div>
              </div>
            </Card.Header>
          </Row>
        </div>

        {events.length == 0 ? (
          <div className="w-full text-center no-event mt-4">
            <div className="no-event-txt">Event di kategori ini tidak ditemukan,</div>
            <div className="no-event-txt">coba ubah filter kategori</div>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(13rem, 1fr)) ",
                gap: "1rem",
                padding: "3rem",
              }}
            >
              {events.map((event, i) => {
                return <EventCard key={`${event.id}-${i}`} event={event} />;
              })}
            </div>
          </div>
        )}

        {!reachEnd ? (
          <div ref={targetRef} style={{ display: "flex", justifyContent: "center" }}>
            <LoadMore />
          </div>
        ) : (
          <div className="text-gray-500 py-10 text-xs text-center">
            <p>You has been reach the end of products</p>
          </div>
        )}

        {activeCartEvent ? (
          <div className="cart-popup" onClick={() => setShowSidebar(true)}>
            <div className="cart-popup-inner">
              <div className="popup-badge">{1}</div>
              <BsFillCartCheckFill size={50} />
            </div>
          </div>
        ) : null}

        {activeCartEvent ? (
          <Offcanvas show={showSidebar} onHide={() => setShowSidebar(false)} placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <div className="fw-bold">Checkout Cart</div>
                <div className="cart-sidebar-subtitle">
                  Database : {dbList?.alias || ""} [{dbList?.id || ""}] | Cabang: {branch?.name || ""} [
                  {branch?.id || ""}] | Warehouse: {warehouse?.name || ""} [{warehouse?.id || ""}]
                </div>
                <div className="cart-sidebar-subtitle">Officer Name : {user?.name || ""}</div>
                <div className="cart-sidebar-subtitle">
                  Cart No. {activeCartEvent?.id || ""} | Order ID : {activeCartEvent?.order_id || ""} |
                  <span className="ms-1 delete-cart" onClick={removeFromCart}>
                    <HiOutlineTrash className="me-1" />
                    Delete Cart
                  </span>
                </div>
                <div className="cart-sidebar-subtitle">
                  Customer : {activeCustomer?.fullname || ""} | {activeCustomer?.email || ""} |{" "}
                  {activeCustomer?.phone || ""}
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Card body className="cart-item-container">
                <Container fluid>
                  <Row>
                    <Col
                      sm={2}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <a className="normal-anchor">
                        <img
                          style={{ width: "100px", cursor: "pointer" }}
                          src={activeCartEvent?.event_detail?.image || null}
                          alt={activeCartEvent?.event_detail?.name || "image"}
                        />
                      </a>
                    </Col>

                    <Col sm={4} className="container-cat-cart">
                      <div style={{ marginLeft: "5%" }}>
                        <h6 className="cart-name-product">{activeCartEvent?.event_detail?.name || ""}</h6>
                        <div>
                          {(activeCartEvent?.discount || 0) > 0 && (
                            <NumberFormat
                              value={activeCartEvent?.regular_price || "0"}
                              displayType={"text"}
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix={"IDR "}
                              className="discount-cart-price"
                            />
                          )}
                          <br />
                          <NumberFormat
                            value={activeCartEvent?.total || "0"}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix={"IDR "}
                            className="total-cart-price"
                          />
                        </div>
                      </div>
                    </Col>

                    <Col
                      sm={6}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <div className="quantity-container" style={{ marginRight: "1.5rem" }}>
                        <IoIosRemoveCircle
                          size={30}
                          className="cart-sidebar-icon-add"
                          color={qty > 1 ? "#ed5e26" : "gray"}
                          onClick={() =>
                            setQty((prev) => {
                              return prev <= 1 ? prev : prev - 1;
                            })
                          }
                        />
                        <span style={{ display: "inline-block", margin: "0 .5rem" }}>{qty}</span>

                        <IoIosAddCircle
                          size={30}
                          className="cart-sidebar-icon-add me-2"
                          onClick={() => setQty((prev) => prev + 1)}
                        />
                      </div>
                      <div className="bin-icon">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 100, hide: 200 }}
                          overlay={
                            <Tooltip id="button-tooltip" {...activeCartEvent}>
                              Hapus Item
                            </Tooltip>
                          }
                        >
                          <IoMdTrash onClick={removeFromCart} size={30} className="cart-sidebar-icon-remove" />
                        </OverlayTrigger>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Card>

              <div className="tax-sidebar-item">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row d-flex align-items-center">
                      <div className="col-md-9">
                        Metode Pembayaran: <span className="text-danger">*</span>
                      </div>
                      <div className="col-md-3">
                        <div className="position-relative">
                          <select
                            className="form-control"
                            value={payment_channel}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                payment_channel: parseInt(e.target.value),
                              })
                            }
                          >
                            {(channels || []).length > 0 &&
                              channels.map((channel) => (
                                <option key={channel.id} value={channel.id}>
                                  {channel.name}
                                </option>
                              ))}
                          </select>

                          <IoMdArrowDropdown className="arrow-select" size={25} color="gray" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <TotalSummary isProduct={false} activeCart={activeCartEvent} />

              <hr />

              <div className="d-flex justify-content-between align-items-center">
                <div className="fw-bold">Total Belanja</div>

                <div className="fw-bold" style={{ color: "red" }}>
                  <NumberFormat
                    value={activeCartEvent?.total || "0"}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix={"IDR "}
                  />
                </div>
              </div>
              <button
                className="btn btn-primary btn-checkout fw-bold mt-2"
                disabled={isDisabled}
                onClick={() => setModalCheckoutEvent(true)}
              >
                Checkout
              </button>
            </Offcanvas.Body>
          </Offcanvas>
        ) : null}
      </div>
    </div>
  );
}
