export const ProtectionList = [
  {
    id: "1",
    plan_id: "PLAN-1-01",
    deskripsi: "(Harga Rp. 0 s/d Rp. 400.000,-)",
    plan_title: "DOSS Protection Moveable All Risk 1 Tahun",
    premi: "29000",
    min_price: "0",
    max_price: "400000",
    durasi: "365",
    durasi_label: "1 Tahun",
    available_store_code: "DOSS,GSD,DOSSINDO",
    keterangan: "20% dari nilai kerusakan",
  },
  {
    id: "2",
    plan_id: "PLAN-1-02",
    deskripsi: "(Harga Rp. 400.001,- s/d Rp. 999.000,-)",
    plan_title: "DOSS Protection Moveable All Risk 1 Tahun",
    premi: "49000",
    min_price: "400001",
    max_price: "999000",
    durasi: "365",
    durasi_label: "1 Tahun",
    available_store_code: "DOSS,GSD,DOSSINDO",
    keterangan: "20% dari nilai kerusakan",
  },
  {
    id: "3",
    plan_id: "PLAN-1-03",
    deskripsi: "(Harga Rp. 1.000.000,- s/d Rp. 50.000.000,-)",
    plan_title: "DOSS Protection Moveable All Risk 1 Tahun",
    premi: "99000",
    min_price: "1000000",
    max_price: "50000000",
    durasi: "365",
    durasi_label: "1 Tahun",
    available_store_code: "DOSS,GSD,DOSSINDO",
    keterangan: "20% dari nilai kerusakan",
  },
  {
    id: "4",
    plan_id: "PLAN-1-04",
    deskripsi: "(Harga Rp. 50.000.001,- s/d Rp. 200.000.000,-)",
    plan_title: "DOSS Protection Moveable All Risk 1 Tahun",
    premi: "500000",
    min_price: "50000001",
    max_price: "200000000",
    durasi: "365",
    durasi_label: "1 Tahun",
    available_store_code: "DOSS,GSD,DOSSINDO",
    keterangan: "20% dari nilai kerusakan",
  },
  {
    id: "5",
    plan_id: "PLAN-2-01",
    deskripsi: "(Harga Rp. 0 s/d Rp. 25.000.000,-)",
    plan_title: "DOSS Protection Extended Warranty 1 + 1 Tahun",
    premi: "50000",
    min_price: "0",
    max_price: "25000000",
    durasi: "365",
    durasi_label: "1 Tahun",
    available_store_code: "DOSS,GSD,DOSSINDO",
    keterangan: "20% dari nilai kerusakan",
  },
  {
    id: "6",
    plan_id: "PLAN-2-02",
    deskripsi: "(Harga Rp. 25.000.001,- s/d 50.000.000,-)",
    plan_title: "DOSS Protection Extended Warranty 1 + 1 Tahun",
    premi: "75000",
    min_price: "25000001",
    max_price: "50000000",
    durasi: "365",
    durasi_label: "1 Tahun",
    available_store_code: "DOSS,GSD,DOSSINDO",
    keterangan: "20% dari nilai kerusakan",
  },
  {
    id: "7",
    plan_id: "EW DOSS PAKET 3",
    deskripsi: "(Harga Rp. 0 sd Rp. 25.000.000)",
    plan_title: "DOSS Protection Extended Warranty 2 + 1 Tahun",
    premi: "65000",
    min_price: "0",
    max_price: "25000000",
    durasi: "730",
    durasi_label: "2 Tahun",
    available_store_code: "DOSS",
    keterangan: "20% dari nilai kerusakan",
  },
  {
    id: "8",
    plan_id: "EW DOSS PAKET 4",
    deskripsi: "Harga Rp. 25.000.001,- sd Rp. 50.000.000,-",
    plan_title: "DOSS Protection Extended Warranty 2 + 1 Tahun",
    premi: "97500",
    min_price: "25000001",
    max_price: "50000000",
    durasi: "730",
    durasi_label: "2 Tahun",
    available_store_code: "DOSS",
    keterangan: "20% dari nilai kerusakan",
  },
  {
    id: "9",
    plan_id: "EW DOSSINDO PAKET 3",
    deskripsi: "Harga Rp. 0,- sd Rp. 25.000.000,-",
    plan_title: "DOSS Protection Extended Warranty 2 + 1 Tahun",
    premi: "65000",
    min_price: "0",
    max_price: "25000000",
    durasi: "730",
    durasi_label: "2 Tahun",
    available_store_code: "DOSSINDO",
    keterangan: "20% dari nilai kerusakan",
  },
  {
    id: "10",
    plan_id: "EW DOSSINDO PAKET 4",
    deskripsi: "Harga Rp. 25.000.001,- sd Rp. 50.000.000,-",
    plan_title: "DOSS Protection Extended Warranty 2 + 1 Tahun",
    premi: "97500",
    min_price: "25000001",
    max_price: "50000000",
    durasi: "730",
    durasi_label: "2 Tahun",
    available_store_code: "DOSSINDO",
    keterangan: "20% dari nilai kerusakan",
  },
  {
    id: "11",
    plan_id: "EW GSD PAKET 3",
    deskripsi: "Harga Rp. 0,- sd Rp. 25.000.000,-",
    plan_title: "DOSS Protection Extended Warranty 2 + 1 Tahun",
    premi: "65000",
    min_price: "0",
    max_price: "25000000",
    durasi: "730",
    durasi_label: "2 Tahun",
    available_store_code: "GSD",
    keterangan: "20% dari nilai kerusakan",
  },
  {
    id: "12",
    plan_id: "EW GSD PAKET 4",
    deskripsi: "Harga Rp. 25.000.001,- sd Rp. 50.000.000,-",
    plan_title: "DOSS Protection Extended Warranty 2 + 1 Tahun",
    premi: "97500",
    min_price: "25000001",
    max_price: "50000000",
    durasi: "730",
    durasi_label: "2 Tahun",
    available_store_code: "GSD",
    keterangan: "20% dari nilai kerusakan",
  },
];
