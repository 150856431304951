import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../configs/AppContext";
import Skeleton from "react-loading-skeleton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { IoIosCheckmarkCircle } from "react-icons/io";

import Officer from "../assets/images/doss-officer-webp.webp";

import Sidebar from "../components/Sidebar";
import TopNavbar from "../components/TopNavbar";

import { APISV } from "../configs/API";
import cleanObject from "../helpers/cleanObject";
import ModalFormUpdatePhone from '../components/ModalFormUpdatePhone'

const CustomerSearch = () => {
  const [state, dispatch] = useContext(AppContext);
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [phone, setPhone] = useState("");
  const branch = localStorage.getItem("branch");
  const warehouse = localStorage.getItem("warehouse");
  const [modalForm, setModalForm] = useState(false)
  const removeDialCode = (value, dialCode) => {
    return value.replace(`${dialCode}`, "");
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let payload = {
        email,
        phone: removeDialCode(phone, dialCode),
        dial_code: dialCode,
      };
      payload = cleanObject(payload);
      const res = await APISV.post("account/check", payload);

      if (res.status == 200) {
        if (res.data.error == 422) {
          alert(JSON.stringify(res.data.errors));
        }
        if (res.data.error === 404) {
          alert(res.data.description);
        }
        if (res.data.error === 0) {
          setCustomer(res.data.data);
        }
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };

  const handleChoose = async () => {
    try {
      setLoading(true);

      if (state.activeCart) {
        const res = await APISV.delete(`s-cart/${state.activeCart.id}`);

        if (res.data.error == 0) {
          dispatch({
            type: "DELETE_ACTIVE_CART",
          });
          dispatch({
            type: "DELETE_ACTIVE_TAX",
          });

          dispatch({
            type: "SET_CART_SHOW",
            payload: false,
          });
          dispatch({
            type: "SET_SN_SHOW",
            payload: false,
          });
          dispatch({
            type: "SET_TAX_SHOW",
            payload: false,
          });
        } else {
          alert(res.data.description);
        }
      }

      if (customer.phone == null) {
        setModalForm(true);
      } else {
        dispatch({
          type: "SET_ACTIVE_CUSTOMER",
          payload: customer,
        });

        navigate("/products");
      }


      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.response.data.message);
    }
  };

  useEffect(() => {
    if (!branch || !warehouse) {
      navigate("/warehouse");
    }
  }, [branch, warehouse]);

  return (
    <div className="main-container">
      <Sidebar />

      <div
        className="w-100"
        style={{
          paddingLeft: state.collapse ? "80px" : "270px",
          transition: "width, left, right, 0.3s",
        }}
      >
        <TopNavbar title="Customer" disableSearch={true} />

        <div className="d-flex justify-content-between px-4">
          <h1 className="header-h1">Search Customer</h1>

          <button
            className="btn btn-primary customer-add-btn"
            onClick={() => navigate("/customers/create")}
          >
            Tambah Customer
          </button>
        </div>

        <div className="row mt-4">
          <div className="col-md-6">
            <form onSubmit={(e) => handleSearch(e)}>
              <div className="form-group px-4 mt-2">
                <label>Alamat Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Masukan alamat email customer"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <label className="mt-3">Nomor Handphone</label>
                <PhoneInput
                  country={"id"}
                  placeholder=""
                  value={phone}
                  onChange={(e, data) => {
                    setPhone(e);
                    setDialCode(data.dialCode);
                  }}
                  countryCodeEditable={false}
                  inputClass="form-control w-100 h-48"
                />
                {/* <input
                  type="tel"
                  className="form-control"
                  placeholder="Masukan nomor handphone customer"
                  name="phone"
                  value={phone || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                /> */}

                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-4"
                  disabled={email === "" && phone === ""}
                >
                  Cari Customer
                </button>
              </div>
            </form>
          </div>

          <div className="col-md-6">
            {!customer && !loading && (
              <div className="customer-search-card">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={Officer}
                    alt="logo account"
                    className="officer-search-img"
                  />

                  <div className="officer-search-name mt-4">
                    Cari Customer berdasarkan Email atau Nomor Handphone
                  </div>
                  <div className="customer-search-info mt-3">
                    Hasil pencarian akan muncul disini
                  </div>
                </div>
              </div>
            )}

            {loading && (
              <div className="customer-search-card">
                <div className="d-flex flex-column align-items-center">
                  <div className="customer-search-img">
                    <Skeleton circle style={{ height: "100%" }} />
                  </div>

                  <div className="customer-search-name w-100 mt-2">
                    <Skeleton />
                  </div>
                  <div className="customer-search-info w-100">
                    <Skeleton />
                  </div>
                  <div className="w-100">
                    <Skeleton />
                  </div>
                </div>

                <div className="w-100">
                  <Skeleton style={{ height: "30px" }} />
                  <Skeleton style={{ height: "30px" }} />
                </div>
              </div>
            )}

            {customer && !loading && (
              <>
                <div className="customer-search-card">
                  <div className="d-flex flex-column align-items-center">
                    <div className="customer-search-img">
                      <img src={customer.avatar} alt="logo account" />
                      {/* <Skeleton circle style={{ height: "100%" }} /> */}
                    </div>

                    <div className="customer-search-name w-100 mt-2">
                      {customer.fullname}
                    </div>
                    <div className="customer-search-info">{customer.email}</div>
                    <div className="customer-search-info d-flex align-items-center">
                      {customer.phone}{" "}
                      {customer.unverified_phone == null && (
                        <span className="ms-1">
                          <IoIosCheckmarkCircle color="#3ec690" size={20} />
                        </span>
                      )}
                    </div>
                  </div>

                  <div>
                    <button
                      className="btn btn-primary w-100"
                      onClick={handleChoose}
                    >
                      Pilih Customer
                    </button>
                    <button className="btn btn-secondary w-100 mt-2">
                      Ubah Profile
                    </button>
                  </div>
                </div>

              </>
            )}
          </div>
        </div>
      </div>
      <ModalFormUpdatePhone
        show={modalForm}
        handleClose={() => setModalForm(false)}
        customer={customer}
      />
    </div>
  );
};

export default CustomerSearch;