import React, { useContext } from "react";
import { BsFillCartCheckFill } from "react-icons/bs";
import { AppContext } from "../configs/AppContext";

const CartPopup = () => {
  const [state, dispatch] = useContext(AppContext);

  const openCart = () => {
    dispatch({
      type: "SET_CART_SHOW",
      payload: true,
    });
  };

  return state.activeCart && state.activeCart.items.length > 0 ? (
    <div className="cart-popup" onClick={openCart}>
      <div className="cart-popup-inner">
        <div className="popup-badge">{state.activeCart.items.length}</div>
        <BsFillCartCheckFill size={50} />
      </div>
    </div>
  ) : null;
};

export default CartPopup;
