import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { IoMdClose, IoMdCopy } from "react-icons/io";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { AppContext } from "../configs/AppContext";

const ModalQRInvoice = ({ show, handleClose, url }) => {
  const [state, dispatch] = useContext(AppContext);

  const handleCopy = () => {
    dispatch({
      type: "SET_TOAST",
      payload: {
        isOpen: true,
        message: "Berhasil Salin URL Pembayaran",
      },
    });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="">
          <div className="d-flex justify-content-between w-100">
            <div className="fw-bold">Link Pembayaran</div>
            <IoMdClose
              className="cursor-pointer"
              size={20}
              onClick={handleClose}
            />
          </div>
          {/* <div className="qr-container">
            <QRCode
              size={255}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={url}
              viewBox={`0 0 255 255`}
            />
          </div> */}

          <div>
            {/* <div className="fw-bold my-2">Link Pembayaran</div> */}

            <div className="url-container">
              <div>{url}</div>

              <CopyToClipboard text={url} onCopy={handleCopy}>
                <div className="copy-icon">
                  <IoMdCopy size={30} />
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalQRInvoice;
