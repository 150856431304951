import React, { useContext, useEffect, useState } from "react";
import { Col, Offcanvas, Row, Spinner } from "react-bootstrap";
import { AppContext } from "../configs/AppContext";
import NumberFormat from "react-number-format";
import { APISV, APIUTILS } from "../configs/API";
import TotalSummary from "./TotalSummary";
import TitleSidebar from "./TitleSidebar";
import { Autocomplete, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import ModalAddressList from "./ModalAddressList";
import { IoIosRefreshCircle } from "react-icons/io";

const ShippingSidebar = () => {
  const [state, dispatch] = useContext(AppContext);
  const [pickupMethod, setPickupMethod] = useState("normal");
  const [modalAddress, setModalAddress] = useState(false);
  const [addressList, setAddressList] = useState(null);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [province, setProvince] = useState(null);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);
  const [loadingCity, setLoadingCity] = useState(false);
  const [subdistricts, setSubdistricts] = useState([]);
  const [subdistrict, setSubdistrict] = useState(null);
  const [loadingSubdistrict, setLoadingSubdistrict] = useState(false);
  const [needSave, setNeedSave] = useState(false);
  const [needShippingAddress, setNeedShippingAddress] = useState(false);
  const { activeCart, activeTax, activeCustomer } = state;
  const [formData, setFormData] = useState({
    "id": "",
    "destination": "",
    "address_name": "",
    "address": "",
    "postal_code": "",
    "phone": "0",
    "province_id": "",
    "city_id": "",
    "subdistrict_id": "",
  });

  useEffect(() => {
    setIsLoading(true)
    getProvinces()
    clearData()
  }, [])

  useEffect(() => {
    if (pickupMethod === "delivery_now") {
      setNeedShippingAddress(true)
      clearData()
      return
    }
    setNeedShippingAddress(false)
  }, [pickupMethod])

  const clearData = () => {
    setProvince(null)
    setCities([])
    setCity(null)
    setSubdistricts([])
    setSubdistrict(null)
    setNeedShippingAddress(true)
    setNeedSave(false)
    setFormData({
      ...formData,
      "address_name": "",
      "address": "",
      "postal_code": "",
      "phone": "",
      "province_id": "",
      "city_id": "",
      "subdistrict_id": "",
      "id": "",
      "destination": "",
    })
  }

  useEffect(() => {
    setNeedSave(pickupMethod === "delivery_now" ? true : false)
    if (formData?.address_name && formData?.phone && formData?.subdistrict_id && subdistrict) {
      setNeedShippingAddress(false)
    }
  }, [formData.address, formData.address_name, formData.subdistrict_id, formData.phone, formData.postal_code, subdistrict])

  const getProvinces = async () => {
    try {
      const res = await APIUTILS.get('/shipping/province')

      if (res?.data?.error === 0) {
        setProvinces(res?.data?.data?.map((val) => ({ ...val, value: val.province_id, label: val?.province })))
      } else {
        alert(res?.data?.message || "Failed get provinces")
      }
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      alert(error)
    }
  }

  const handleChangeProvince = async (id) => {
    setProvince(provinces.find(e => e.value === id) || null)
    setCities([])
    setCity(null)
    setSubdistricts([])
    setSubdistrict(null)
    setFormData({
      ...formData,
      province_id: id || "",
      city_id: "",
      subdistrict_id: "",
    })
    setLoadingCity(true)
    try {
      const res = await APIUTILS.get(`/shipping/city?province_id=${id}`)

      if (res?.data?.error === 0) {
        setCities(res?.data?.data?.map((val) => ({ ...val, value: val.city_id, label: val.city_name })))
      } else {
        alert(res?.data?.message || "Failed get cities")
      }
      setLoadingCity(false)
    } catch (error) {
      console.error(error)
      setLoadingCity(false)
      alert(error)
    }
  }

  const handleChangeCity = async (id) => {
    let selectedCity = cities.find(e => e.value === id)
    setCity(selectedCity || null)
    setSubdistricts([])
    setSubdistrict(null)
    setFormData({
      ...formData,
      city_id: id || "",
      postal_code: selectedCity?.postal_code || "",
      subdistrict_id: "",

    })
    setLoadingSubdistrict(true)
    try {
      const res = await APIUTILS.get(`/shipping/subdistrict?city_id=${id}`)

      if (res?.data?.error === 0) {
        setSubdistricts(res?.data?.data?.map((val) => ({ ...val, value: val.subdistrict_id, label: val.subdistrict_name })))
      } else {
        alert(res?.data?.message || "Failed get subdistricts")
      }
      setLoadingSubdistrict(false)
    } catch (error) {
      console.error(error)
      setLoadingSubdistrict(false)
      alert(error)
    }
  }

  const handleChangeDistrict = async (id) => {
    setSubdistrict(subdistricts.find(e => e.value === id) || null)
    setFormData({
      ...formData,
      subdistrict_id: id,

    })
  }

  const handleClose = () => {
    clearData()
    dispatch({
      type: "SET_SHIPPING_SHOW",
      payload: false,
    });

    dispatch({
      type: "SET_SN_SHOW",
      payload: true,
    });
  };

  const handleNext = () => {
    dispatch({
      type: "SET_SHIPPING_SHOW",
      payload: false,
    });
    dispatch({
      type: "SET_TAX_SHOW",
      payload: true,
    });
  };

  const handleChooseAddress = async (value) => {
    setFormData({
      ...formData,
      "id": value?.id,
      "destination": value?.id,
      "address_name": value?.address_name,
      "address": value?.address,
      "postal_code": value?.postal_code,
      "phone": value?.phone,
      "province_id": value?.province_id,
      "city_id": value?.city_id,
      "subdistrict_id": value?.subdistrict_id,
      "default": value?.is_default || false
    })

    if (value?.province_id) {
      if (provinces?.length > 0) {
        setProvince(provinces?.find(e => e.value === value.province_id))
      }
      setLoadingCity(true)

      try {
        const res = await APIUTILS.get(`/shipping/city?province_id=${value?.province_id}`)

        if (res?.data?.error === 0 && res?.data?.data?.length > 0) {
          var datas = res?.data?.data?.map((val) => ({ ...val, value: val.city_id, label: val.city_name }))
          setCities(datas)

          if (value?.city_id) {
            setCity(datas.find(e => e.value === value.city_id) || null)
            setLoadingSubdistrict(true)

            const resCities = await APIUTILS.get(`/shipping/subdistrict?city_id=${value?.city_id}`)

            if (resCities?.data?.error === 0 && resCities?.data?.data?.length > 0) {
              var dataSubdistricts = resCities?.data?.data?.map((val) => ({ ...val, value: val.subdistrict_id, label: val.subdistrict_name }))
              setSubdistricts(dataSubdistricts)
              if (value?.subdistrict_id) {
                setSubdistrict(dataSubdistricts?.find(e => e.value === value.subdistrict_id || null))
              }
            }

          }
        }
        setLoadingCity(false)
        setLoadingSubdistrict(false)
      } catch (error) {
        console.error(error);
        setLoadingCity(false)
        setLoadingSubdistrict(false)

      }
    }

    if (value?.address_name && value?.subdistrict_id && value?.phone) {
      setNeedShippingAddress(false)
    }
    if (!value?.address_name || !value?.subdistrict_id || !value?.phone) {
      setNeedShippingAddress(true)
    }

    setModalAddress(false);
  };

  const openNewAddress = () => {
    setModalAddress(false);
    setPickupMethod("delivery_now")
  };

  const loadAddress = async () => {
    try {
      setLoadingAddress(true);
      const payload = { user_id: activeCustomer.id };
      const res = await APISV.post("account/address", payload);

      if (res.data.error === 0) {
        if (res.data.data.length > 0) {
          setAddressList(res.data.data);
          setModalAddress(true);

          if (document.getElementById("cust-address")) {
            document.getElementById("cust-address").blur();
          }
        } else {
          setAddressList(res.data.data);
          setModalAddress(true);
        }
      } else {
        alert(res.data.description);
      }

      setLoadingAddress(false);
    } catch (error) {
      setLoadingAddress(false);
      alert(error.response.data.message);
    }
  };

  const submitShippingAddress = async () => {
    if (pickupMethod === 'normal') {
      handleNext()
      return
    }

    let destination = formData?.destination

    if (!destination || needSave) {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        }

        let body = {
          "destination": formData?.destination || "",
          "name": formData?.address_name || "",
          "address": formData?.address || "",
          "subdistrict_id": formData?.subdistrict_id || 0,
          "city_id": formData?.subdistrict_id || 0,
          "province_id": formData?.province_id || 0,
          "postal_code": formData?.postal_code || "",
          "phone": formData?.phone || "",
          "default": formData?.is_default || false
        }

        const res = await APIUTILS.post("/customers/save_address", body, config);

        if (res.data.error === 0) {

          if (!destination) {
            destination = res?.data?.data?.destination || ""
            setFormData({
              ...formData,
              id: res?.data?.data?.destination || "",
              destination: res?.data?.data?.destination || "",
            })
          }
          setNeedSave(false)
          setNeedShippingAddress(false)
          dispatch({
            type: "SET_TOAST",
            payload: {
              isOpen: true,
              message: `Success save ${formData?.destination ? "" : "new"} address`,
            },
          });
        } else {
          alert("Failed save address");
        }

      } catch (error) {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
        if (error.response.data) {
          alert(error.response.data.message);
        } else {
          alert(error);
        }
      }

    }

    if (!state?.loading) {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
    }

    try {

      const body = {
        "address_id": destination
      }

      const res = await APISV.post(`s-cart/${activeCart.id}/shipping`, body);

      if (res.data.error === 0) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            isOpen: true,
            message: "Success save shipping address",
          },
        });
        handleNext()
      } else {
        alert(`Failed error ${res?.data?.error}: ${res?.data?.message || "failed save shipping!"}`)
      }
      setIsLoading(false)
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });

    } catch (error) {
      setIsLoading(false);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      if (error?.response?.data) {
        alert(error?.response?.data?.message || "Failed save shipping!");
      } else {
        alert(error);
      }
    }
  };

  return activeCart ? (
    <>
      <Offcanvas show={state.shippingShow} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <TitleSidebar activeCart={activeCart} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="fw-bold">SHIPPING PRODUCT</div>
          <hr />
          <div className="cart-sidebar-item">
            <FormControl>
              <label id="pickup-product" style={{ fontSize: "16px" }}>Shipping Method: <span className="text-danger">*</span></label>
              <RadioGroup
                row
                aria-labelledby="pickup-product"
                name="row-pickup-product"
              >
                <FormControlLabel value="normal" checked={pickupMethod === 'normal'} onChange={(e) => setPickupMethod(e.target.value)} control={<Radio />} label="Normal" />
                <FormControlLabel value="delivery_now" checked={pickupMethod === 'delivery_now'} onChange={(e) => setPickupMethod(e.target.value)} control={<Radio />} label="Delivery Now" />
              </RadioGroup>
            </FormControl>

            <div className="mt-3">
              {pickupMethod === 'delivery_now' &&
                <div>
                  <div className="d-flex align-items-center">
                    <div
                      className="btn btn-secondary mt-3 mr-3"
                      style={{
                        fontSize: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={loadAddress}
                    >
                      {loadingAddress && (
                        <div className="tax-spinner">
                          <Spinner
                            animation="border"
                            style={{
                              color: "#E66423",
                              width: "15px",
                              height: "15px",
                            }}
                          />
                        </div>
                      )}
                      {!loadingAddress && "Show Shipping Address"}
                    </div>
                    <div
                      className="mt-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: '1rem',
                      }}
                      onClick={clearData}
                    >
                      <IoIosRefreshCircle
                        size={30}
                        className="cursor-pointer"
                        onClick={clearData}
                      />
                    </div>
                  </div>
                  <div className="create-address mt-3">
                    <form>
                      <Row>
                        <Col xs={12} md={7}>
                          <FormControl className="mb-3 d-flex alight-items-center">
                            <label id="pickup-product" style={{ fontSize: "16px" }}>Shipping Name: <span className="text-danger">*</span></label> {" "}
                            <input
                              type="text"
                              placeholder="Input shipping name"
                              className="form-control w-100 mt-2"
                              name="address_name"
                              value={formData?.address_name ?? ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </FormControl>
                        </Col>
                        <Col xs={12} md={7}>
                          <FormControl className="mb-3 d-flex alight-items-center">
                            <label id="phone-number" style={{ fontSize: "16px" }}>Phone Number: <span className="text-danger">*</span></label> {" "}
                            <input
                              type="text"
                              placeholder="Input phone number"
                              className="form-control w-100 mt-2"
                              name="phone"
                              value={formData?.phone ?? ""}
                              maxLength={16}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value.replace(/[^0-9]/g, "").replace(/^1-9+(?=\d)/, "0"),
                                })
                              }
                            />
                          </FormControl>
                        </Col>
                        <Col xs={12} md={7}>
                          <FormControl className="mb-3">
                            <label id="province" style={{ fontSize: "16px" }}>Province: <span className="text-danger">*</span></label>
                            <Autocomplete
                              disablePortal
                              id="province"
                              options={provinces}
                              value={province}
                              onChange={(e, val) => handleChangeProvince(val?.value)}
                              disabled={provinces?.length === 0}
                              loading={isLoading}
                              className="mt-2"
                              renderInput={(params) => <TextField {...params}
                                placeholder={isLoading ? "Loading…" : "Change Province"}
                                sx={{
                                  "& .MuiAutocomplete-inputRoot": {
                                    minWidth: 300,
                                    borderRadius: "9px"
                                  },
                                }}
                              />}
                            />
                          </FormControl>
                        </Col>
                        <Col xs={12} md={7}>
                          <FormControl className="mb-3">
                            <label id="city" style={{ fontSize: "16px" }}>City: <span className="text-danger">*</span></label>
                            <Autocomplete
                              disablePortal
                              id="city"
                              options={cities}
                              value={city}
                              onChange={(e, val) => handleChangeCity(val?.value)}
                              disabled={cities?.length === 0}
                              loading={loadingCity}
                              className="mt-2"
                              renderInput={(params) => <TextField {...params}
                                placeholder={loadingCity ? "Loading…" : "Change City"}
                                sx={{
                                  "& .MuiAutocomplete-inputRoot": {
                                    minWidth: 300,
                                    borderRadius: "9px"
                                  },
                                }}
                              />}
                            />
                          </FormControl>
                        </Col>
                        <Col xs={12} md={7}>
                          <FormControl className="mb-3">
                            <label id="subdistrict" style={{ fontSize: "16px" }}>Subdistrict: <span className="text-danger">*</span></label>
                            <Autocomplete
                              id="subdistrict"
                              options={subdistricts}
                              value={subdistrict}
                              onChange={(e, val) => handleChangeDistrict(val?.value)}
                              disabled={subdistricts?.length === 0}
                              loading={loadingSubdistrict}
                              className="mt-2"
                              renderInput={(params) => <TextField {...params}
                                placeholder={loadingSubdistrict ? "Loading…" : "Change Subdistrict"}
                                sx={{
                                  "& .MuiAutocomplete-inputRoot": {
                                    minWidth: 300,
                                    borderRadius: "9px"
                                  },
                                }}
                              />}
                            />
                          </FormControl>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormControl className="mb-3 d-flex alight-items-center">
                            <label id="pickup-product" style={{ fontSize: "16px" }}>Postal Code</label> {" "}
                            <input
                              type="text"
                              placeholder="Input postal code"
                              className="form-control w-50 mt-2"
                              name="postal_code"
                              maxLength={5}
                              value={formData?.postal_code ?? ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value.replace(/[^0-9]/g, ""),
                                })
                              }
                            />
                          </FormControl>
                        </Col>
                        <Col xs={12} md={7}>
                          <FormControl className="mb-3 d-flex alight-items-center">
                            <label id="address" style={{ fontSize: "16px" }}>Address</label> {" "}
                            <textarea
                              rows="4"
                              cols="31"
                              className="form-control mb-3 mt-2"
                              style={{ height: "auto" }}
                              placeholder="Input address"
                              name="address"
                              value={formData?.address}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </FormControl>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              }
            </div>
          </div>

          <TotalSummary activeCart={activeCart} tax={activeTax} />

          <hr />

          <div className="d-flex justify-content-between align-items-center">
            <div className="fw-bold">Total Belanja</div>

            <div className="fw-bold" style={{ color: "red" }}>
              <NumberFormat
                value={activeCart.total}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                prefix={"IDR "}
              />
            </div>
          </div>

          <button
            className="btn btn-primary btn-checkout fw-bold mt-2"
            onClick={submitShippingAddress}
            disabled={isLoading || needShippingAddress || (pickupMethod === "delivery_now" && (!formData?.address_name || !formData?.phone || !formData?.subdistrict_id || !subdistrict))}
          >
            {isLoading && (
              <div className="tax-spinner">
                <Spinner
                  animation="border"
                  style={{
                    color: "#f7e3e1",
                    width: "15px",
                    height: "15px",
                  }}
                />
              </div>
            )}
            {!isLoading && "Lanjutkan"}
          </button>
        </Offcanvas.Body>
      </Offcanvas>

      {activeCart ? (
        <ModalAddressList
          show={modalAddress}
          title="Shipping Address"
          handleClose={() => setModalAddress(false)}
          addressList={addressList}
          chooseAddress={handleChooseAddress}
          openNewAddress={openNewAddress}
        />
      ) : null}
    </>
  ) : null;
};

export default ShippingSidebar;
