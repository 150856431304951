import React, { useEffect, useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { AppContext } from "../configs/AppContext";
import { APIO2O, setAuthToken, setAuthTokenO2O } from "../configs/API";

const AccurateCallback = () => {
  const [state, dispatch] = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const redirect = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const payload = { aol_code: code, redirect_uri: `${process.env.REACT_APP_REDIRECT}/accurate_callback` };
      // const payload = { aol_code: code };
      const res = await APIO2O.post("token/aol", payload);

      if (res.data.error == 0) {
        localStorage.setItem("userInfo", JSON.stringify(res.data.data.user));

        localStorage.setItem("access_token", res.data.data.access_token);

        // setAuthTokenO2O(res.data.data.access_token);

        const config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${res.data.data.token_type} ${res.data.data.access_token}`,
          },
        };
        const res2 = await APIO2O.post("token/platform", null, config);

        localStorage.setItem("token", res2.data.data.access_token);

        // setAuthToken(res2.data.data.access_token, res.data.data.user.id);

        dispatch({
          type: "SET_LOGIN",
        });

        const currentDate = new Date();
        let expired_at = new Date(currentDate);
        expired_at.setHours(currentDate.getHours() + 6);

        localStorage.setItem("expired_at", expired_at);

        navigate("/customers/search");
      } else {
        let arr = [];
        res.data.errors.map((err) => arr.push(err.e));
        alert(arr.join("\n"));
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);

      alert(error.response.data.message);

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };

  useEffect(() => {
    redirect();
  }, []);
  return <div></div>;
};

export default AccurateCallback;