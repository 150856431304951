import React, { useState, useContext } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { IoMdClose, IoMdArrowDropdown } from "react-icons/io";
import { APISV } from "../configs/API";
import { AppContext } from "../configs/AppContext";

const ModalNewAddress = ({ show, handleClose, handleChangeCustAddress }) => {
  const [state, dispatch] = useContext(AppContext);
  const { activeCustomer } = state;

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    user_id: activeCustomer ? activeCustomer.id : "",
    address_name: "",
    address: "",
  });

  const { address_name, address } = formData;

  const saveAddress = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const res = await APISV.post("account/address/create", formData);
      console.log("res", res);

      if (res.data.error == 0) {
        handleChangeCustAddress(address);
        handleClose();
        setFormData({
          user_id: activeCustomer.id,
          address_name: "",
          address: "",
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  return (
    <Modal centered show={show} onHide={handleClose} backdrop="static">
      <Modal.Body>
        <div className="position-relative">
          <div className="d-flex justify-content-between mb-4">
            <div className="modal-coupon-header">
              Tambah Alamat Baru Customer
            </div>
            <IoMdClose
              className="cursor-pointer"
              size={20}
              onClick={handleClose}
            />
          </div>

          <div className="address-list">
            <form onSubmit={saveAddress}>
              <input
                type="text"
                placeholder="Nama Penerima"
                className="form-control mb-3"
                name="address_name"
                value={address_name}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  })
                }
              />

              {/* <input
                type="text"
                placeholder="No Handphone/Whatsapp"
                className="form-control mb-3"
              />

              <div className="position-relative mb-3">
                <select className="form-control" name="province">
                  <option value="">Provinsi</option>
                  <option value="jakarta">DKI Jakarta</option>
                </select>

                <IoMdArrowDropdown
                  className="arrow-select"
                  size={25}
                  color="gray"
                />
              </div>

              <div className="position-relative mb-3">
                <select className="form-control" name="province">
                  <option value="">Kota/Kabupaten</option>
                  <option value="jakarta-pusat">Kota Jakarta Pusat</option>
                </select>

                <IoMdArrowDropdown
                  className="arrow-select"
                  size={25}
                  color="gray"
                />
              </div>

              <div className="position-relative mb-3">
                <select className="form-control" name="province">
                  <option value="">Kecamatan</option>
                  <option value="gambir">Gambir</option>
                </select>

                <IoMdArrowDropdown
                  className="arrow-select"
                  size={25}
                  color="gray"
                />
              </div>

              <input
                type="text"
                placeholder="Kode Pos"
                className="form-control mb-3"
              /> */}

              <textarea
                rows="3"
                cols="31"
                className="form-control mb-4"
                style={{ height: "auto" }}
                placeholder="Alamat Lengkap"
                name="address"
                value={address}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  })
                }
              />

              <div className="pt-2">
                <button
                  type="submit"
                  className="btn btn-primary text-nowrap w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      animation="border"
                      style={{
                        color: "#ffff",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  ) : (
                    "Simpan dan Gunakan"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalNewAddress;
