export const idrToNumber = (value) => {
  let str = value.replace(/\./g, "");
  str = str.replace(/\s/g, "");
  str = str.replace("IDR", "");
  if (str) {
    return parseInt(str);
  } else {
    return 0;
  }
};
