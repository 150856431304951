import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Offcanvas, Spinner } from "react-bootstrap";
import { AppContext } from "../configs/AppContext";
import NumberFormat from "react-number-format";
import moment from "moment";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";

import { IoMdArrowDropdown } from "react-icons/io";

import { APIO2O, APISV, APIUTILS } from "../configs/API";
import { formatNominal } from "../helpers/formatNominal";
import TotalSummary from "./TotalSummary";
import TitleSidebar from "./TitleSidebar";
import ModalAddressList from "./ModalAddressList";
import ModalNewAddress from "./ModalNewAddress";

const TaxSidebar = () => {
  const branch = JSON.parse(localStorage.getItem("branch"));
  const [state, dispatch] = useContext(AppContext);
  const [channels, setChannels] = useState([]);
  const [salesChannels, setSalesChannels] = useState([]);
  const [stores, setStores] = useState([]);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [modalAddress, setModalAddress] = useState(false);
  const [addressList, setAddressList] = useState(null);
  const [modalNewAddress, setModalNewAddress] = useState(false);
  const [sales, setSales] = useState(null);
  const [paymentType, setPaymentType] = useState("full_payment");
  const [dpList, setDpList] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [formData, setFormData] = useState({
    payment_channel: 1,
    location: "",
    branch_name: branch.name,
    branch_id: branch.id,
    sales_channel: "",
    customer_address: "",
    cash: "",
    edc: "",
    transfer: "",
    dp_amount: "",
    note: "",
    tax_date: moment(new Date()).format("YYYY-MM-DD"),
  });

  const navigate = useNavigate();
  const animatedComponents = makeAnimated();

  const convertNumber = (value) => {
    if (value === "") {
      return 0;
    } else {
      return parseInt(value.substring(4).replace(/\./g, ""));
    }
  };

  const getDPAmount = (value) => {
    if (!value) return 0;

    let arr = value.split(",");
    return parseInt(arr[arr.length - 1]);
  };

  const getDPId = (value) => {
    let arr = value.split(",");
    return parseInt(arr[0]);
  };

  const {
    tax_date,
    customer_address,
    payment_channel,
    location,
    sales_channel,
    cash,
    edc,
    transfer,
    note,
    branch_name,
    branch_id,
    dp_amount,
  } = formData;

  const { activeCart, activeTax, activeCustomer } = state;

  const handleClose = () => {
    dispatch({
      type: "SET_TAX_SHOW",
      payload: false,
    });
    dispatch({
      type: "SET_SHIPPING_SHOW",
      payload: true,
    });
  };

  const getChannel = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APIUTILS.get("pay/list_channel");
      const arrayData = res.data.data;
      arrayData.pop();

      if (res.data.error == 0) {
        setChannels(arrayData);
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const getStores = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APIUTILS.get("location/list");

      if (res.data.error == 0) {
        setStores(res.data.data);
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const getSalesChannel = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APIO2O.get("/platform/sales-channel");

      if (res.data.error == 0) {
        setSalesChannels(res.data.data);
        let getDefault = res.data.data.find((sales) => sales.default == true);
        setFormData({ ...formData, sales_channel: getDefault.value });
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const getDownPayment = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APISV.get(`/dp?customer_id=${activeCustomer.id}`);
      console.log("res", res.data);

      if (res.data.error == 0) {
        setDpList(res.data.data);
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const handleCheckout = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      await applySalesPerson();

      let payload = {
        payment_channel: parseInt(payment_channel),
        location: parseInt(location),
        customer_address,
        branch_name,
        sales_channel,
        cash: convertNumber(cash),
        edc: convertNumber(edc),
        transfer: convertNumber(transfer),
        note,
      };

      if (dp_amount) {
        payload = {
          ...payload,
          dp_id: getDPId(dp_amount),
        };
        console.log('[log] DP Added')
      }

      const res = await APISV.post(`s-cart/${activeCart.id}/checkout`, payload);

      if (res.data.error == 0) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            isOpen: true,
            message: "Berhasil checkout transaksi",
          },
        });

        dispatch({
          type: "DELETE_ACTIVE_CART",
        });
        dispatch({
          type: "DELETE_ACTIVE_TAX",
        });
        dispatch({
          type: "SET_TAX_SHOW",
          payload: false,
        });

        if (payment_channel === 2) {
          navigate(
            { pathname: "/customers/history" },
            { state: { inv_url: res.data.data.inv_url } }
          );
        } else {
          dispatch({
            type: "DELETE_ACTIVE_CUSTOMER",
          });
          navigate("/");
        }
        resetData();
      } else if (res.data.error == 403) {
        if (res.data.errors.some((error) => error["id"] && error["message"])) {
          alert(`${res.data.errors[0].id} \n${res.data.errors[0].message}`);
        } else {
          alert(
            res.data.errors.map((error) => {
              return `${error}\n`;
            })
          );
        }
      } else {
        alert(res.data.message);
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error.response.data.message);
    }
  };

  const loadAddress = async () => {
    try {
      setLoadingAddress(true);
      const payload = { user_id: activeCustomer.id };
      const res = await APISV.post("account/address", payload);

      if (res.data.error == 0) {
        if (res.data.data.length > 0) {
          setAddressList(res.data.data);
          setModalAddress(true);

          if (document.getElementById("cust-address")) {
            document.getElementById("cust-address").blur();
          }
        } else {
          setAddressList(res.data.data);
          setModalAddress(true);
        }
      } else {
        alert(res.data.description);
      }

      setLoadingAddress(false);
    } catch (error) {
      setLoadingAddress(false);
      alert(error.response.data.message);
    }
  };

  const filterEmployee = async (inputValue) => {
    try {
      const res = await APIO2O.get(`aol/employee`, {
        params: {
          query: inputValue,
          branch_id: branch.id,
        },
      });
      return res.data.data.employees;
    } catch (error) {
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const applySalesPerson = async () => {
    try {
      const body = sales;
      const res = await APISV.post(
        `s-cart/${activeCart.id}/salespersons`,
        body
      );
      if (res.data.error == 0) {
        console.log("apply sales success");
      } else {
        alert(res.data.description);
      }
    } catch (error) {
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error);
      }
    }
  };

  const handleChangeSales = (value) => {
    let filtered = value.map(function (n) {
      return {
        id: n.id,
        name: n.name,
        number: n.number,
      };
    });
    const sales = {
      salespersons: filtered,
    };
    setSales(sales);
  };

  const resetData = () => {
    setPaymentType("full_payment");
    setFormData({
      payment_channel: 1,
      location: "",
      branch_name: branch.name,
      branch_id: branch.id,
      sales_channel: "",
      customer_address: "",
      cash: "",
      edc: "",
      transfer: "",
      dp_amount: "",
      note: "",
      tax_date: moment(new Date()).format("YYYY-MM-DD"),
    });
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterEmployee(inputValue));
    });

  const handleChooseAddress = (value) => {
    setModalAddress(false);
    let string = `${value.address_name} \n${value.address} ${value.postal_code ? `, ${value.postal_code}` : ""
      } ${value.phone ? `\nPhone: ${value.phone}` : ""}`;

    setFormData({ ...formData, customer_address: string });
  };

  const openNewAddress = () => {
    setModalAddress(false);
    setModalNewAddress(true);
  };

  const handleChangeCustAddress = (value) => {
    setFormData({ ...formData, customer_address: value });
  };

  const handleDisabled = () => {
    if (
      paymentType === "full_payment" &&
      convertNumber(cash) +
      convertNumber(edc) +
      convertNumber(transfer) +
      getDPAmount(dp_amount) !==
      activeCart.total
    ) {
      setIsDisabled(true);
    } else if (
      paymentType === "down_payment" &&
      getDPAmount(dp_amount) +
      convertNumber(cash) +
      convertNumber(edc) +
      convertNumber(transfer) !==
      activeCart.total
    ) {
      setIsDisabled(true);
    } else if (!location) {
      setIsDisabled(true);
    } else if (!customer_address) {
      setIsDisabled(true);
    }
    else {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    activeCart && handleDisabled();
  }, [
    payment_channel,
    paymentType,
    cash,
    edc,
    transfer,
    location,
    dp_amount,
    activeCart,
    customer_address
  ]);

  useEffect(() => {
    state.taxShow && getChannel();
    state.taxShow && getStores();
    state.taxShow && getSalesChannel();
    state.taxShow && getDownPayment();
  }, [state.taxShow]);

  return (
    <>
      {activeCart ? (
        <ModalAddressList
          show={modalAddress}
          handleClose={() => setModalAddress(false)}
          addressList={addressList}
          chooseAddress={(e) => handleChooseAddress(e)}
          openNewAddress={openNewAddress}
        />
      ) : null}

      {activeCart ? (
        <ModalNewAddress
          show={modalNewAddress}
          handleClose={() => setModalNewAddress(false)}
          handleChangeCustAddress={handleChangeCustAddress}
        />
      ) : null}

      {activeCart ? (
        <Offcanvas show={state.taxShow} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <TitleSidebar activeCart={activeCart} />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="tax-sidebar-item">
              <div className="row">
                <div className="col-md-6">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-5">Pilih Sales Person:</div>

                    <div className="col-md-7">
                      <AsyncSelect
                        isMulti
                        components={animatedComponents}
                        name="employees"
                        defaultOptions
                        loadOptions={promiseOptions}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option}
                        onChange={handleChangeSales}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        maxMenuHeight={280}
                      />
                    </div>
                  </div>

                  <div className="row d-flex align-items-center mt-3">
                    <div className="col-md-5">
                      Tgl Faktur Pajak: <span className="text-danger">*</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="date"
                        className="form-control"
                        name="tax_date"
                        value={tax_date}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="row d-flex align-items-center mt-3">
                    <div className="col-md-5">
                      Alamat Customer: <span className="text-danger">*</span>
                    </div>
                    <div className="col-md-7">
                      {customer_address && (
                        <div className="tax-address">
                          <textarea
                            id="cust-address"
                            rows="4"
                            cols="31"
                            className="form-control"
                            style={{ height: "auto" }}
                            name="customer_address"
                            value={customer_address}
                            disabled
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                [e.target.name]: e.target.value,
                              })
                            }
                          ></textarea>
                        </div>
                      )}

                      <div className="d-flex gap-2">
                        <div
                          className="btn btn-secondary w-50 mt-2"
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={loadAddress}
                        >
                          {loadingAddress && (
                            <div className="tax-spinner">
                              <Spinner
                                animation="border"
                                style={{
                                  color: "#E66423",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            </div>
                          )}
                          {!loadingAddress && "Daftar alamat customer"}
                        </div>

                        <div
                          className="btn btn-secondary w-50 mt-2"
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={openNewAddress}
                        >
                          {loadingAddress && (
                            <div className="tax-spinner">
                              <Spinner
                                animation="border"
                                style={{
                                  color: "#E66423",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            </div>
                          )}
                          Tambah alamat customer
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex align-items-center mt-3">
                    <div className="col-md-5">
                      Cabang: <span className="text-danger">*</span>
                    </div>
                    <div className="col-md-7">
                      <div className="position-relative">
                        <select
                          className="form-control"
                          name="location"
                          value={location}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        >
                          <option value="">Pilih salah satu</option>
                          {stores &&
                            stores.map((sales) => (
                              <option key={sales.id} value={sales.id}>
                                {sales.name}
                              </option>
                            ))}
                        </select>

                        <IoMdArrowDropdown
                          className="arrow-select"
                          size={25}
                          color="gray"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex align-items-center mt-3">
                    <div className="col-md-5">
                      Sales Channel: <span className="text-danger">*</span>
                    </div>
                    <div className="col-md-7">
                      <div className="position-relative">
                        <select
                          className="form-control"
                          name="sales_channel"
                          value={sales_channel}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        >
                          {salesChannels &&
                            salesChannels.map((sales) => (
                              <option key={sales.key} value={sales.value}>
                                {sales.value}
                              </option>
                            ))}
                        </select>

                        <IoMdArrowDropdown
                          className="arrow-select"
                          size={25}
                          color="gray"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-5">
                      Metode Pembayaran: <span className="text-danger">*</span>
                    </div>
                    <div className="col-md-7">
                      <div className="position-relative">
                        <select
                          className="form-control"
                          value={payment_channel}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              payment_channel: parseInt(e.target.value),
                            })
                          }
                        >
                          {channels &&
                            channels.map((channel) => (
                              <option key={channel.id} value={channel.id}>
                                {channel.name}
                              </option>
                            ))}
                        </select>

                        <IoMdArrowDropdown
                          className="arrow-select"
                          size={25}
                          color="gray"
                        />
                      </div>
                    </div>
                  </div>

                  <>
                    {dpList && dpList.length > 0 && (
                      <div className="row d-flex align-items-center mt-3">
                        <div className="col-md-5">DP:</div>
                        <div className="col-md-7">
                          <div className="position-relative">
                            <select
                              className="form-control"
                              value={dp_amount}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  dp_amount: e.target.value,
                                })
                              }
                            >
                              <option value="">Pilih salah satu</option>
                              {dpList &&
                                dpList.map((dp) => (
                                  <option
                                    key={dp.id}
                                    value={
                                      dp.id +
                                      "," +
                                      dp.description +
                                      "," +
                                      dp.amount
                                    }
                                  >
                                    #{dp.id} - {dp.description} /{" "}
                                    {formatNominal(dp.amount)}
                                  </option>
                                ))}
                            </select>

                            <IoMdArrowDropdown
                              className="arrow-select"
                              size={25}
                              color="gray"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row d-flex align-items-center mt-3">
                      <div className="col-md-5">Cash:</div>
                      <div className="col-md-7">
                        <NumberFormat
                          value={cash}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              cash: e.target.value,
                            })
                          }
                          prefix={"IDR "}
                          thousandSeparator="."
                          decimalSeparator=","
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row d-flex align-items-center mt-3">
                      <div className="col-md-5">EDC:</div>
                      <div className="col-md-7">
                        <NumberFormat
                          value={edc}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              edc: e.target.value,
                            })
                          }
                          prefix={"IDR "}
                          thousandSeparator="."
                          decimalSeparator=","
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row d-flex align-items-center mt-3">
                      <div className="col-md-5">Transfer:</div>
                      <div className="col-md-7">
                        <NumberFormat
                          value={transfer}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              transfer: e.target.value,
                            })
                          }
                          prefix={"IDR "}
                          thousandSeparator="."
                          decimalSeparator=","
                          className="form-control"
                        />
                      </div>
                    </div>
                  </>

                  {payment_channel === 3 && (
                    <div className="row d-flex align-items-center mt-3">
                      <div className="col-md-5">Transfer:</div>
                      <div className="col-md-7">
                        <NumberFormat
                          value={transfer}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              transfer: e.target.value,
                            })
                          }
                          prefix={"IDR "}
                          thousandSeparator="."
                          decimalSeparator=","
                          className="form-control"
                        />
                      </div>
                    </div>
                  )}

                  <div className="row d-flex mt-3">
                    <div className="col-md-5">Keterangan:</div>
                    <div className="col-md-7">
                      <textarea
                        rows="2"
                        cols="31"
                        className="form-control"
                        style={{ height: "auto" }}
                        name="note"
                        value={note}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <TotalSummary activeCart={activeCart} tax={activeTax} />

            <hr />

            <div className="d-flex justify-content-between align-items-center">
              <div className="fw-bold">Total Belanja</div>

              <div className="fw-bold" style={{ color: "red" }}>
                <NumberFormat
                  value={activeCart.total}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={"IDR "}
                />
              </div>
            </div>

            <button
              className="btn btn-primary btn-checkout fw-bold mt-2"
              disabled={isDisabled}
              onClick={handleCheckout}
            >
              Checkout
            </button>
          </Offcanvas.Body>
        </Offcanvas>
      ) : null}
    </>
  );
};

export default TaxSidebar;