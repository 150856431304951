import React, { useContext, useState } from "react";
import { Modal, ListGroup, Button, Card, Container } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";

//CSS
// import "./ModalSortEvent.scss";

//Configs

const ModalSortEvent = ({ show, handleClose, chooseSort, sortBy }) => {
  const handleChoose = (e) => {
    chooseSort(e);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Container className="pb-4">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "5%",
          }}
        >
          <h5 className="change-address-title">Urutkan Berdasarkan</h5>
          <MdClose className="close" size={25} onClick={() => handleClose()} />
        </div>

        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item
              className="sort-list-container"
              onClick={() => handleChoose("&sort=desc&sort_field=date")}
            >
              <div
                className="list-container"
                style={{
                  color:
                    sortBy === "&sort=desc&sort_field=date" ? "#E66424" : null,
                  fontWeight:
                    sortBy === "&sort=desc&sort_field=date" ? "600" : null,
                }}
              >
                <div>Tanggal Terdekat</div>
                <AiOutlineRight />
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              className="sort-list-container"
              onClick={() => handleChoose("&sort=asc&sort_field=date")}
            >
              <div
                className="list-container"
                style={{
                  color:
                    sortBy === "&sort=asc&sort_field=date" ? "#E66424" : null,
                  fontWeight:
                    sortBy === "&sort=asc&sort_field=date" ? "600" : null,
                }}
              >
                <div>Tanggal Terjauh</div>
                <AiOutlineRight />
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Container>
    </Modal>
  );
};

export default ModalSortEvent;
