import React, { useContext } from "react";
import { ToastContainer, Toast } from "react-bootstrap";

import { AppContext } from "../configs/AppContext";

const ToastMessage = () => {
  const [state, dispatch] = useContext(AppContext);

  const handleClose = () => {
    dispatch({
      type: "SET_TOAST",
      payload: {
        isOpen: false,
        message: state.toast.message,
      },
    });
  };

  return (
    <Toast
      onClose={handleClose}
      show={state.toast.isOpen}
      delay={3000}
      autohide
    >
      <Toast.Header className="py-3">
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto" style={{ color: "#ee6026" }}>
          {state.toast.message}
        </strong>
      </Toast.Header>
    </Toast>
  );
};

export default ToastMessage;
