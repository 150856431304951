import React, { useEffect, useState, useContext, useRef } from "react";
import { Spinner } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { IoIosAddCircle, IoIosRemoveCircle, IoMdTrash } from "react-icons/io";
import { HiCheckCircle } from "react-icons/hi";

import { APIDOSS, APISV } from "../configs/API";
import { AppContext } from "../configs/AppContext";

const CartChildItem = ({ data, handleDelete }) => {
  let free = data;

  function deleteItem(data) {
    return handleDelete(data)
  }

  return (<div key={free.id} className="cart-sidebar-card mb-3">
    <div className="cart-sidebar-inner">
      <img
        src={free.product.featured_image}
        alt={free.product.name}
        title={free.product.name}
        className="cursor-pointer"
        onClick={() =>
          window.open(
            `https://doss.co.id/product/${free.product.slug}`,
            "_blank"
          )
        }
      />

      <div className="ms-3">
        <div className="cart-sidebar-title-2">#{free.product.id}</div>
        <div
          className="cart-sidebar-title cursor-pointer"
          onClick={() =>
            window.open(
              `https://doss.co.id/product/${free.product.slug}`,
              "_blank"
            )
          }
        >
          {free.product.name}
        </div>
        <div className="d-flex align-items-center">
          <span className="cart-sidebar-black me-1">Price : </span>

          <NumberFormat
            value={free.regular_price}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"IDR "}
            className="cart-sidebar-black fw-bold"
          />

          <div className="mx-2">|</div>
          <span className="cart-sidebar-black me-1">Discount : </span>

          <NumberFormat
            value={free.regular_price - free.item_price}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"IDR "}
            className="cart-sidebar-black fw-bold"
          />
        </div>
        <div>
          <span className="cart-sidebar-black">Total : </span>
          <NumberFormat
            value={free.item_price}
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"IDR "}
            className="cart-sidebar-price"
          />{" "}
          <span>({free.quantity} barang)</span>
        </div>

        <div className="cart-sidebar-qty mt-2">
          <div className="me-2">Stock : {free.product.stock}</div>
          {free.fixed_price_type !== "FIXED_PRICE_FREE_ITEM" && (
            <IoMdTrash
              size={30}
              className="cart-sidebar-icon-remove"
              onClick={() => deleteItem(free)}
            />
          )}
        </div>

      </div>
    </div>
  </div>);
}

const CartItem = ({
  item,
  handleDecrease,
  handleIncrease,
  handleDelete,
  handleAddCart,
  getLatestCartInfo,
}) => {
  const [state, dispatch] = useContext(AppContext);
  const [pwpProducts, setPWPProducts] = useState(null);
  const [show, setShow] = useState(false);
  const [protectionList, setProtectionList] = useState([])
  const [inputQuantity, setInputQuantity] = useState(item.quantity);
  const { activeCart } = state;


  const getProtectionList = async () => {
    try {
      const res = await APIDOSS.get(`protection?product_id=${item.product_id}`);
      setProtectionList(res.data.data);
    } catch (error) {
      alert(error);
    }
  };

  const getPWPProducts = async () => {
    try {
      const res = await APIDOSS.get(`product/${item.product_id}`);
      if (res.data.error == 0) {
        const array = res.data.data.promo_pwp_product;

        const anotherOne = item.child_items || [];

        const filteredArray = array.filter(function (array_el) {
          return (
            anotherOne.filter(function (anotherOne_el) {
              return anotherOne_el.product_id == array_el.discounted_product_id;
            }).length == 0
          );
        });

        setPWPProducts(filteredArray);
      } else {
        alert(res.data.description);
      }
    } catch (error) {
      alert(error);
    }
  };

  const applyDossProtection = async (protect) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const payload = {
        item_id: item.id,
        plan_id: protect.plan_id,
      };

      const res = await APISV.post(`s-cart/${item.cart_id}/warranty`, payload);

      if (res.data.error == 0) {
        getLatestCartInfo();
      } else {
        alert(res.data.description);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error);
    }
  };

  const deleteDossProtection = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await APISV.delete(
        `s-cart/${item.cart_id}/warranty/${item.id}`
      );

      if (res.data.error == 0) {
        getLatestCartInfo();
      } else {
        alert(res.data.description);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error);
    }
  };

  useEffect(() => {
    getPWPProducts();
    getProtectionList();
  }, [activeCart]);

  function increaseQuantity() {
    if (item.quantity >= item.product.stock) return;
    handleIncrease(item)
    setInputQuantity(prev => prev + 1);
  }

  function decreaseQuantity() {
    if (item.quantity <= 1) return;
    handleDecrease(item)
    setInputQuantity(prev => prev - 1);
  }


  function deleteItem(data) {
    return handleDelete(data)
  }

  return (
    <>
      <div className="cart-sidebar-card my-3">
        <div key={item.id} className="cart-sidebar-inner">
          <img
            src={item.product.featured_image}
            alt={item.product.name}
            title={item.product.name}
            className="cursor-pointer"
            onClick={() =>
              window.open(
                `https://doss.co.id/product/${item.product.slug}`,
                "_blank"
              )
            }
          />

          <div className="ms-3">
            <div className="cart-sidebar-title-2">#{item.product.id}</div>
            <div
              className="cart-sidebar-title cursor-pointer"
              onClick={() =>
                window.open(
                  `https://doss.co.id/product/${item.product.slug}`,
                  "_blank"
                )
              }
            >
              {item.product.name}
            </div>
            {item.discount > 0 && (
              <div className="d-flex align-items-center">
                <span className="cart-sidebar-black me-1">Price : </span>

                <NumberFormat
                  value={item.item_price}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={"IDR "}
                  className="cart-sidebar-black fw-bold"
                />

                <div className="mx-2">|</div>
                <span className="cart-sidebar-black me-1">Discount : </span>

                <NumberFormat
                  value={item.discount}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={"IDR "}
                  className="cart-sidebar-black fw-bold"
                />
              </div>
            )}
            <div>
              <span className="cart-sidebar-black">Total : </span>
              <NumberFormat
                value={item.total}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                prefix={"IDR "}
                className="cart-sidebar-price"
              />{" "}
              <span>({item.quantity} barang)</span>
            </div>

            <div className="cart-sidebar-qty mt-2">
              <div className="me-2">Stock : {item.product.stock}</div>
              <IoIosRemoveCircle
                size={30}
                className="cart-sidebar-icon-add"
                color={item.quantity > 1 ? "#ed5e26" : "gray"}
                onClick={decreaseQuantity}
              />
              <IoIosAddCircle
                size={30}
                className="cart-sidebar-icon-add me-2"
                color={item.quantity < item.product.stock ? "#ed5e26" : "gray"}
                onClick={increaseQuantity}
              />
              <IoMdTrash
                size={30}
                className="cart-sidebar-icon-remove"
                onClick={() => deleteItem(item)}
              />
            </div>
          </div>
        </div>

        {item.product.protection_applicable && (
          <>
            <hr />
            {protectionList &&
              protectionList.map((protect) => (
                <div
                  key={protect.id}
                  className="d-flex align-items-center text-md mt-1"
                >
                  <span className="me-2">
                    {protect.name} -{" "}
                    <NumberFormat
                      value={protect.price * item.quantity}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={"IDR "}
                    />{" "}
                  </span>
                  {item.applied_protection &&
                    item.applied_protection.length > 0 &&
                    item.applied_protection.find(
                      (prt) => prt.plan_id === protect.plan_id
                    ) ? (
                    <HiCheckCircle size={20} color="#1DAA36" />
                  ) : (
                    <span
                      className="add-protect-btn"
                      onClick={() => applyDossProtection(protect)}
                    >
                      Tambah
                    </span>
                  )}
                </div>
              ))}
            {item.applied_protection && item.applied_protection.length > 0 && (
              <div
                className="remove-protect-btn mt-1"
                onClick={deleteDossProtection}
              >
                Hapus Protection
              </div>
            )}
          </>
        )}
      </div>

      {/* Item Free Bundle */}
      {item.free_items &&
        item.free_items.map((free) => (
          <div key={free.id} className="cart-sidebar-card mb-3">
            <div className="cart-sidebar-inner">
              <img
                src={free.product.featured_image}
                alt={free.product.name}
                title={free.product.name}
                className="cursor-pointer"
                onClick={() =>
                  window.open(
                    `https://doss.co.id/product/${free.product.slug}`,
                    "_blank"
                  )
                }
              />

              <div className="ms-3">
                <div className="cart-sidebar-title-2">#{free.product.id}</div>
                <div
                  className="cart-sidebar-title cursor-pointer"
                  onClick={() =>
                    window.open(
                      `https://doss.co.id/product/${free.product.slug}`,
                      "_blank"
                    )
                  }
                >
                  {free.product.name}
                </div>
                <div className="d-flex align-items-center">
                  <span className="cart-sidebar-black me-1">Price : </span>

                  <NumberFormat
                    value={free.regular_price}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix={"IDR "}
                    className="cart-sidebar-black fw-bold"
                  />

                  <div className="mx-2">|</div>
                  <span className="cart-sidebar-black me-1">Discount : </span>

                  <NumberFormat
                    value={free.regular_price - free.item_price}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix={"IDR "}
                    className="cart-sidebar-black fw-bold"
                  />
                </div>
                <div>
                  <span className="cart-sidebar-black">Total : </span>
                  <NumberFormat
                    value={free.item_price}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix={"IDR "}
                    className="cart-sidebar-price"
                  />{" "}
                  <span>({free.quantity} barang)</span>
                </div>

                <div className="cart-sidebar-qty mt-2">
                  <div className="me-2">Stock : {free.product.stock}</div>
                  <IoMdTrash
                    size={30}
                    className="cart-sidebar-icon-remove"
                    onClick={() => handleDelete(free)}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}

      {/* Item Group */}
      {item.group_items && item.group_items.length > 0 && (
        <div className="fw-bold mb-3" style={{ color: "#646464" }}>
          Daftar Group Item :
        </div>
      )}

      {item.group_items &&
        item.group_items.length > 0 &&
        item.group_items.map((item) => (
          <div key={item.id} className="cart-sidebar-card mb-3">
            <div className="cart-sidebar-inner">
              <img
                src={item.image}
                alt={item.name}
                title={item.name}
                className="cursor-pointer"
              />

              <div className="ms-3">
                <div className="cart-sidebar-title-2">#{item.id}</div>
                <div className="cart-sidebar-title cursor-pointer">
                  {item.name}
                </div>
                <div>
                  <span className="cart-sidebar-black">Harga item : </span>
                  <NumberFormat
                    value={item.price}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix={"IDR "}
                    className="cart-sidebar-price"
                  />{" "}
                </div>
                <div>Qty : {item.quantity} barang</div>
              </div>
            </div>
          </div>
        ))}

      {/* Child Products Selected List */}
      {item.child_items &&
        item.child_items.map((free) => (
          <div key={free.id}>
            <CartChildItem handleDelete={handleDelete} item={item} data={free} cartId={activeCart.id} />
          </div>
        ))}

      {/* PWP Products List */}
      {pwpProducts && show && (
        <div className="fw-bold mb-3" style={{ color: "#646464" }}>
          Daftar Produk PWP :
        </div>
      )}

      {pwpProducts &&
        show &&
        pwpProducts.map((pwp) => (
          <div key={pwp.id} className="cart-sidebar-card mb-3">
            <div className="cart-sidebar-inner">
              <img
                src={pwp.images[0]}
                alt={pwp.name}
                title={pwp.name}
                className="cursor-pointer"
                onClick={() =>
                  window.open(
                    `https://doss.co.id/product/${pwp.slug}`,
                    "_blank"
                  )
                }
              />

              <div className="ms-3">
                <div className="cart-sidebar-title-2">#{pwp.product_id}</div>
                <div
                  className="cart-sidebar-title cursor-pointer"
                  onClick={() =>
                    window.open(
                      `https://doss.co.id/product/${pwp.slug}`,
                      "_blank"
                    )
                  }
                >
                  {pwp.name}
                </div>
                <div>
                  <span className="cart-sidebar-black">Harga PWP : </span>
                  <NumberFormat
                    value={pwp.pwp_price}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix={"IDR "}
                    className="cart-sidebar-price"
                  />{" "}
                </div>

                <div className="cart-sidebar-qty mt-2">
                  <IoIosAddCircle
                    size={30}
                    className="cart-sidebar-icon-add me-2"
                    color="#ed5e26"
                    onClick={() => handleAddCart(pwp)}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      <div
        className={
          pwpProducts == null
            ? "pwp-container"
            : pwpProducts && pwpProducts.length == 0
              ? "pwp-container-disable"
              : "pwp-container"
        }
        onClick={() => pwpProducts && pwpProducts.length > 0 && setShow(!show)}
      >
        {pwpProducts == null && (
          <Spinner
            animation="border"
            style={{ color: "#ffff", width: "15px", height: "15px" }}
          />
        )}

        {pwpProducts &&
          pwpProducts.length > 0 &&
          !show &&
          "Lihat daftar produk PWP"}
        {pwpProducts &&
          pwpProducts.length > 0 &&
          show &&
          "Sembunyikan daftar produk PWP"}
      </div>
    </>
  );
};

export default CartItem;