import React from "react";
import { Modal } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";

const ModalAddressList = ({
  show,
  title,
  handleClose,
  addressList,
  chooseAddress,
  openNewAddress,
}) => {
  return (
    <Modal centered show={show} onHide={handleClose} backdrop="static">
      <Modal.Body>
        <div className="position-relative">
          <div className="d-flex justify-content-between mb-4">
            <div className="modal-coupon-header">{title || "Alamat Customer"}</div>
            <IoMdClose
              className="cursor-pointer"
              size={20}
              onClick={handleClose}
            />
          </div>

          <div className="address-list">
            {addressList && addressList.length == 0 && (
              <div>
                Customer belum memiliki alamat, <br />
                silahkan buat alamat baru
              </div>
            )}
            {addressList &&
              addressList.map((address, index) => (
                <div key={index} className="address-card mb-2">
                  <div>
                    <div className="fw-bold">{address.address_name}</div>
                    <div>
                      {address.address}, {address.postal_code}
                    </div>
                    <div>Phone: {address.phone}</div>
                  </div>

                  <button
                    className="btn btn-primary text-nowrap"
                    onClick={() => chooseAddress(address)}
                  >
                    Pilih alamat
                  </button>
                </div>
              ))}
          </div>

          <div className="pt-2">
            <button
              className="btn btn-primary text-nowrap w-100"
              onClick={openNewAddress}
            >
              Tambah Alamat Baru
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAddressList;
