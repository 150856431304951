import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import TopNavbar from '../components/TopNavbar';
import { AppContext } from '../configs/AppContext';
import { Spinner } from 'react-bootstrap';
import QrScanner from 'react-qr-scanner'
import { APISV } from '../configs/API';
import moment from 'moment';
import './Scanner.css';


const Scanner = () => {
    const [state, dispatch] = useContext(AppContext);
    const { collapse } = state
    const [inputBarcode, setInputBarcode] = useState('');
    const [barcode, setBarcode] = useState('');
    const [scanning, setScanning] = useState(false);
    const [error, setError] = useState('');
    const [loadingScan, setLoadingScan] = useState(false);
    const [doScan, setDoScan] = useState(false);

    const previewStyle = {
        borderRadius: '10px',
        objectFit: 'cover',
        height: 500,
        width: '100%',
    }

    useEffect(() => {
        setDoScan(false)
        setBarcode("")
        setTimeout(() => {
            setScanning(true)
        }, 1000);
    }, [])

    const handleVisibilityChange = () => {
        if (document.hidden) {
          setScanning(false);
        } else {
          setScanning(true);
        }
      };

    useEffect(() => {
        window.addEventListener('visibilitychange', () => handleVisibilityChange);


        return () => {
            window.removeEventListener('visibilitychange', () => handleVisibilityChange)
        }
    }, [])

    useEffect(() => {
        if (barcode && !doScan) {
            setDoScan(true)
        }
    }, [barcode])

    useEffect(() => {
        if (doScan) {
            sendBarcodeToApi()
        }
    }, [doScan])

    const handleScan = async (data) => {
        setLoadingScan(true)
        if (!!data) {
            console.log(data)
            setBarcode(data?.text || "");
            setScanning(false)
            setLoadingScan(false)
        } else {
            console.log('gak ada data')
        }
    };

    const handleError = (err) => {
        console.error(err);
        setError('Error scanning QR code');
    };

    const handleInputChange = (e) => {
        setInputBarcode(e.target.value);
    };

    function onKeyPressBarcode(event) {
        if (event.keyCode === 13) {
            setInputBarcode(event.target.value);
            setBarcode(event.target.value);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (inputBarcode) {
            setBarcode(inputBarcode)
        }
    };

    const sendBarcodeToApi = async () => {
        const payload = JSON.parse(barcode)
        payload.remark = payload?.activity || `User is arrived at ${moment(new Date()).format("YYYY-MM-DD HH:mm:dd")}`
        delete payload.id
        delete payload.acivity
      
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
        };
        try {
            dispatch({
                type: "SET_LOADING",
                payload: true,
            });
            const response = await APISV.post(`events/event/ticket/attend`, payload, config);

            if ((response?.data?.error || 0) === 0) {
                dispatch({
                    type: "SET_TOAST",
                    payload: {
                        isOpen: true,
                        message: "Scan ticket success",
                    },
                });
                setBarcode("")
                setInputBarcode("")
                setScanning(true)
            } else {
                if (response?.data?.message === 'Can not process current request') {
                    dispatch({
                        type: "SET_TOAST",
                        payload: {
                            isOpen: true,
                            message: `Error:${response?.data.error} | ${response?.data.message}`,
                        },
                    });
                } else {
                    alert(`Error:${response?.data.error} | ${response?.data.message}`);
                }
                setBarcode("")
                setInputBarcode("")
                setScanning(true)
            }
            setDoScan(false)
            dispatch({
                type: "SET_LOADING",
                payload: false,
            });
        } catch (error) {
            setDoScan(false)
            console.log(error);
            dispatch({
                type: "SET_LOADING",
                payload: false,
            });
            alert(error.response.data.message);
        }
    };
    return (
        <div className='main-container pb-5'>
            <Sidebar />
            <div
                className="w-100"
                style={{
                    paddingLeft: collapse ? "80px" : "270px",
                    transition: "width, left, right, 0.3s",

                }}
            >
                <TopNavbar title="Scanner" />
                <div style={{ minHeight: '500px', display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', padding: '3rem' }}>
                    <div className="form-group px-4 mt-2">
                        <div className='d-flex mb-4'>
                            <input
                                autoFocus={true}
                                type="text"
                                className="form-control"
                                placeholder="Enter QR code manually"
                                name="qr-code"
                                value={inputBarcode}
                                onChange={handleInputChange}
                                onKeyDown={onKeyPressBarcode}
                            />
                            <button
                                type="button"
                                className="btn btn-primary"
                                style={{ marginLeft: '10px', width: "100px" }}
                                onClick={handleSubmit}
                            >
                                Scan
                            </button>
                        </div>
                        {scanning &&
                            <div className="qr-scanner-container">
                                <div className="scanner-wrapper">
                                    <QrScanner
                                        delay={500}
                                        legacyMode={scanning}
                                        style={previewStyle}
                                        onError={handleError}
                                        onScan={handleScan}
                                        facingMode="environment"
                                        resolution={800}
                                    />
                                    <div className="scanner-overlay">
                                        <div className="scanner-border">
                                            <div className="scanner-line"></div>
                                            <div className="corner top-left"></div>
                                            <div className="corner top-right"></div>
                                            <div className="corner bottom-left"></div>
                                            <div className="corner bottom-right"></div>
                                        </div>
                                    </div>
                                </div>
                                {barcode && <p>Scanned QR Code: {barcode}</p>}
                                {error && <p>Error: {error.message || 'An error occurred while scanning.'}</p>}
                            </div>
                        }
                    </div>
                    {loadingScan && <Spinner />}
                </div>
            </div>
        </div>
    )

}
export default Scanner